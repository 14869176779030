
export class  Util{
    static getLoggedInUserDetails() {
        return JSON.parse(localStorage.getItem('__LOGGEDIN_USER_DETAILS'));
    }

    static setLoggedInUserDetails(token) {
        return localStorage.setItem('AUTH_TOKEN', token)
    }

    static getAuthToken() {
        return localStorage.getItem('AUTH_TOKEN');
    }

    // froala editor

    static getFroalaEditorKey() {
        let key = "CTD5xD3F1A2D1A8A1wc2DBKSPJ1WKTUCQOd1OURPE1KDc1C-7J2A4D4B3C6E2D1F4G1E1==";
        // let key = "zG5E4H4B3kB6B5C4F6F3E3A3F2B6B4D-13btuH4adc1zhdlA-8H-9G1xA-21==";
        return key;
    }
    
    static updateUserData(data){
        let detail = this.getLoggedInUserDetails();
        detail.email = data.email
        detail.emailVerified = detail.emailVerified
        this.setLoggedInUserDetails(detail)
    }
}