import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import axios from 'axios';
import rootReducer from '../reducers';
import clientMiddleware from './clientMiddleware';
// import promiseMiddleware from 'redux-promise-middleware';
import {baseUrl} from "./constants";

export const client = axios.create({
  baseURL: baseUrl,
  withCredentials: false
});

const configureStore = (options = { logger: true }) => {
  const middleware = [
    thunk,
    clientMiddleware(client),
    // promiseMiddleware()
  ];

  if (process.env.NODE_ENV !== 'production' && options.logger) {
    const logger = createLogger({ collapsed: true });
    middleware.push(logger);
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
  );

  const initialState = {};

  // Create the store
  const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers
  );

  return {
    store,
  };
};

export default configureStore;

