import React, { useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as actions from "../../modules/Documents/actions";
import { formatDate, isEmptyObj, checkIfEqual } from "../../utils/utilServices";

const labels = {
  enableComments: "Enable comments",
  enableSuggestedContent: "Suggest content",
  enableSharing: "Share document",
  enableEmployeeAcknowledge: "Employee acknowledgement"
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});

function DocumentSettingContent({ data, dispatch }) {
  const location = useLocation();
  const [documentName, setDocumentName] = React.useState(data.title);
  const settingsDataRef = useRef();

  let documentChecksFromObj = [];
  Object.keys(data.settings).map((item) => documentChecksFromObj.push({ label: "", key: item, checked: data.settings[item] }));

  const [documentChecks, setDocumentChecks] = React.useState(documentChecksFromObj);
  const [publishType, setPublishType] = React.useState(data.publish.publishType);
  const [publishPasscode, setPasscode] = React.useState(data.publish.publishPasscode);
  const [ackDueDate, setAckDueDate] = React.useState(data.acknowledgeTargetDate || "" );
  const [dateError, setDateError] = React.useState("");

  useEffect(() => {
    let settingsObj = {};
    documentChecks.map(checkbox => settingsObj[checkbox.key] = checkbox.checked);

    if (dateError && documentChecks[3].checked) {
      settingsObj.enableEmployeeAcknowledge = false
    }

    let updatedData = {
      ackDueDate: ackDueDate,
      title: documentName,
      settings: settingsObj,
      publish: {
        publishType,
        publishPasscode
      }
    }

    settingsDataRef.current = updatedData;
  }, [documentChecks, documentName, publishType, publishPasscode, ackDueDate]);

  const getReturnType = () => {
    let type = "";
    ["create-document", "document/"].map(item => {
      if (location.pathname.includes(item)) {
        type = "/?returnType=document";
      }
    })

    return type;
  }

  useEffect(() => {
    return () => {
      let currentData = settingsDataRef.current;

      let publishData = {
        publishType: currentData.publish.publishType !== data.publish.publishType ? currentData.publish.publishType : undefined,
        passcode: currentData.publish.publishPasscode !== data.publish.publishPasscode ? currentData.publish.publishPasscode : undefined
      }

      let updatedData = {
        title: currentData.title !== data.title ? currentData.title : undefined,
        settings: !checkIfEqual(currentData.settings, data.settings) ? currentData.settings : undefined,
        acknowledgeTargetDate: currentData.settings.enableEmployeeAcknowledge ? currentData.ackDueDate : undefined,
        publish: !isEmptyObj(publishData) ? publishData : undefined
      }

      if (!isEmptyObj(updatedData)) {
        dispatch(actions.updateDocument(updatedData, data._id, getReturnType()))
          .then(res => {
            if (res.data.success && location.pathname.includes("create-document")) {
              updateParentWindow();
            }
          });
      }
    }
  }, []);

  const updateParentWindow = () => {
    if (window.opener?.location.pathname === "/documents") {
      window.opener?.location.reload();
    }
  }

  const refreshPasscode = () => {
    let code = randomFixedInteger(6).toString()
    code = code.toString().slice(0, 3) + '-' + code.toString().slice(3)

    setPasscode(code);
  }

  let randomFixedInteger = (length) => {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1));
  }

  const handleDateChange = (e) => {
    let date = new Date(e.target.value);
    let today = new Date()
    setDateError("")
    setAckDueDate(e.target.value)

    if (date < today) {
      setDateError("Due date cannot be earlier than today.")
    }
  }

  return (
    <div className="document-settings-wrapper">
      <section>
        <TextField className="text-field" label="Document name" variant="outlined" value={documentName} onChange={(e) => setDocumentName(e.target.value)} />

        {data.publish.isPublished && <FormControl variant="outlined" className="text-field">
          <InputLabel id="demo-simple-select-outlined-label">Publish status</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={publishType}
            onChange={(e) => setPublishType(e.target.value)}
            label="Publish status"
          >
            <MenuItem value={"public"}>Public</MenuItem>
            <MenuItem value={"protected"}>Passcode protected</MenuItem>
            <MenuItem value={"private"}>Private</MenuItem>
          </Select>
        </FormControl>}

        {publishType === "protected" && <FormControl variant="outlined" className="text-field">
          <InputLabel htmlFor="outlined-adornment-password">Passcode</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            value={publishPasscode}
            // onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={refreshPasscode}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <AutorenewIcon />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>}
      </section>

      <section>
        {
          documentChecks.map((item, index) => {
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={item.checked}
                    onChange={() => setDocumentChecks(documentChecks.map(check => check === item ? { ...check, checked: !item.checked } : check))}
                    name="checkedB"
                    color="primary"
                  />
                }
                className="flip-switch"
                labelPlacement="start"
                label={labels[item.key]}
                key={index}
              />
            );
          })
        }


        {documentChecks[3]?.checked && <TextField
          variant="outlined"
          id="date"
          label="Acknowledgement due date"
          type="date"
          className="text-field"
          value={ackDueDate}
          onChange={(e) => handleDateChange(e)}
          InputLabelProps={{
            shrink: true,
          }}
          error={dateError ? true : false}
          helperText={dateError}
        />}

      </section>

      <section>
        <Grid container spacing={2} className="misc-detail">
          <Grid item xs={6}>Created</Grid>
          <Grid item xs={6}>{formatDate(data.createdAt)}</Grid>

          <Grid item xs={6}>Owner</Grid>
          <Grid item xs={6}>{data.createdBy.firstName} {data.createdBy.lastName}</Grid>

          <Grid item xs={6}>Last published</Grid>
          <Grid item xs={6}>{data.publishedDate}</Grid>
        </Grid>
      </section>
    </div>
  );
}

export default connect(
  mapDispatchToProps
)(DocumentSettingContent);
