import * as types from './constants.js';

export function getWorkSpaceFiles(tab, page, showLoader) {
  let param = "";

  if (tab === "recent") {
    param = `?isRecent=true&page=${page}`
  } else if (tab === "trending") {
    param = `?isTrending=true&page=${page}`
  } else if (tab === "favourites") {
    param = `?isFavourite=true&page=${page}`
  }

  return {
    types: [
      types.GET_WORKSPACE_FILES,
      types.GET_WORKSPACE_FILES_SUCCESS,
      types.GET_WORKSPACE_FILES_FAILURE,
      !showLoader ? "NO_LOADER" : ""
    ],
    promise: client => client.get(`/document${param}`)
  }
}

export function changeWorkspaceMode(mode) {
  return {
    type: types.CHANGE_WORKSPACE_MODE,
    payload : mode,
  }
} 