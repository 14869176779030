import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from "react-router-dom";

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import FolderIcon from '@material-ui/icons/Folder';
import PieChartIcon from '@material-ui/icons/PieChart';
import PeopleIcon from '@material-ui/icons/People';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

import "./header.scss";
import * as actions from '../../modules/Signin/sessionActions';
import * as modalActions from "../Modal/actions";
import Dropdown from "../Dropdown";
import NotificationsDrawer from "../NotificationsDrawer";
import UserDropdown from "./userDropdown";
import { Link } from "react-router-dom";
import { Util } from "../../utils/util";
import { isMobile, profileImagePath } from "../../utils/utilServices";

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('lg')]: {
      width: `100%`,
      marginLeft: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    overflowY: "unset",
    width: drawerWidth,
    [theme.breakpoints.up('lg')]: {
      width: 100,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const mapStateToProps = state => ({
  isLoggedIn: state.session.isLoggedIn,
  user: state.session.user,
  showWalkthrough: state.modal.showWalkthrough
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});

function ResponsiveDrawer(props) {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [mobileBackdropInvisible, setMobileBackdrop] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [headerAnchorEl, setHeaderAnchorEl] = React.useState(null);

  const headerMenuData = [
    {
      icon: <PeopleIcon />,
      text: "Manage users",
      optionName: "manage_users"
    },
    // {
    //   icon: <LiveHelpIcon />,
    //   text: "Help",
    //   optionName: "help"
    // }
  ];

  const headerMainItems = [
    {
      name: "workspace",
      routeTo: "/workspace",
      icon: <DashboardIcon />
    },
    {
      name: "documents",
      routeTo: "/documents",
      icon: <FolderIcon />
    },
    {
      name: "media library",
      routeTo: "/media-library",
      icon: <InsertPhotoIcon />
    },
    {
      name: "analytics",
      routeTo: "/analytics",
      icon: <PieChartIcon />
    },
    // mobile items
    {
      name: "manage users",
      routeTo: "/manage-users",
      icon: <PeopleIcon />
    },
    // {
    //   name: "help",
    //   routeTo: "/help",
    //   icon: <LiveHelpIcon />
    // },
    {
      name: "Add new user",
      action: "openUserModal",
      icon: <PersonAddIcon />
    },
  ];

  const location = useLocation();

  useEffect(() => {
    let headerIndex = headerMainItems.findIndex(e => e.routeTo === location.pathname);
    setSelectedIndex(headerIndex !== -1 ? headerIndex : null);
  }, [location.pathname]);

  useEffect(() => {
    if (props.showWalkthrough && document.body.scrollWidth < 1280) {
      handleDrawerToggle();
      setMobileBackdrop(true);
    } else {
      setMobileBackdrop(false);
    }
  }, [props.showWalkthrough]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClick = (event) => {
    setHeaderAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setHeaderAnchorEl(null);
  };

  const handleSideClickMobile = (item, index) => {
    if (item.action === "openUserModal") {
      props.dispatch(modalActions.toggleUserModal())
    }

    setSelectedIndex(index);
    handleDrawerToggle()
  }

  const handleSideClickDesktop = (index) => {
    setSelectedIndex(index)
  }

  const handleHeaderMenuClick = (option) => {
    switch (option.optionName) {
      case "manage_users": history.push("/manage-users"); break;
      case "help": history.push("/help");break;
      default: break;
    }
  }

  const desktopDrawer = (
    <div>
      <div className={`${classes.toolbar} dummy-toolbar`} />
      <Divider />
      <List className="header-list">
        <ListItem className="list-item" onClick={() => props.dispatch(modalActions.toggleCreateDocumentModal())} id="create-doc-header-button">
          <Tooltip title={"Create new document"} aria-label="add doc">
            <div className="add-icon-box">
              <NoteAddIcon />
            </div>
          </Tooltip>
        </ListItem>
      </List>

      <List id="drawer-items" className="header-list">
        {headerMainItems.slice(0, 4).map((item, index) => {
          return (
            <ListItem
              key={index}
              component={Link} to={item.routeTo}
              onClick={() => handleSideClickDesktop(index)}
              className={`${selectedIndex === index ? 'selected-list-item' : ''} list-item`}
            >
              {item.icon}
              <span>{item.name}</span>
            </ListItem>
          );
        })}
      </List>
      {/* </List> */}
    </div>
  );

  const mobileDrawer = (
    <div className="mobile-drawer-wrapper">
      <div className={`${classes.toolbar} mobile-dummy-toolbar`}>
        <Avatar alt="Remy Sharp" src={profileImagePath(props.user?._id) + '?' + new Date().getTime()} />
        <span>Your Account</span>
      </div>

      <div className="add-person-box" onClick={() => props.dispatch(modalActions.toggleUserModal())} id="add-person-to-team-mobile">
        <PersonAddIcon />
      </div>

      <Divider />
      <List id="drawer-items-mobile">
        {
          headerMainItems.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem button
                  className="mobile-list-item"
                  component={Link}
                  to={item.routeTo ? item.routeTo : ""}
                  onClick={() => handleSideClickMobile(item, index)}
                  style={selectedIndex === index ? { color: "black" } : { color: "#a29999" }}
                >
                  <ListItemIcon style={selectedIndex === index ? { color: "black" } : { color: "#a29999" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
                {index === 3 && <Divider />}
              </React.Fragment>
            );
          })
        }
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  if (!props.user) {
    return (
      <div className={`${classes.root} header-wrapper`}>
        <CssBaseline />
        <AppBar position="fixed" className={`${classes.appBar} no-user-app-toolbar app-toolbar ${props.showWalkthrough && isMobile() ? "walkthrough-bar" : ""}`}>
          <Toolbar>
            <h6>Flipchart</h6>
          </Toolbar>
        </AppBar>
      </div>
    )
  }

  return (
    <div className={`${classes.root} header-wrapper`}>
      <CssBaseline />

      <AppBar position="fixed" className={`${classes.appBar} app-toolbar ${props.showWalkthrough && isMobile() ? "walkthrough-bar" : ""}`}>
        {props.isLoggedIn ?
          <Toolbar>
            <div className="name-div">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>

              <span className="user-name-display">{props.user.firstName} {props.user.lastName}</span>
            </div>

            <div className="header-items-container">
              <SearchIcon className="search-btn" onClick={() => history.push("/search")} />
              {localStorage.getItem('AUTH_TOKEN') && <div className="ml-1"><NotificationsDrawer /></div>}

              <div className="ml-1">
                <UserDropdown user={props.user} />
              </div>

              <div className="ml-1">
              <MoreVertIcon aria-controls="header-menu" aria-haspopup="true" onClick={handleMenuClick} className="more-icon" />
              <Dropdown id="header-menu" anchorEl={headerAnchorEl} handleClose={handleMenuClose} data={headerMenuData} handleMenuClick={(option) => handleHeaderMenuClick(option)} />
              </div>
            </div>
          </Toolbar> :
          <Toolbar>
            <h6>Flipchart</h6>
          </Toolbar>
        }
      </AppBar>

      <nav className="drawer-wrapper" aria-label="mailbox folders" hidden={!props.isLoggedIn}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            className="desktop-drawer"
            container={container}
            BackdropProps={{ invisible: mobileBackdropInvisible }}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {mobileDrawer}
          </Drawer>
        </Hidden>

        <Hidden mdDown implementation="css">
          <Drawer
            className="desktop-drawer"
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {desktopDrawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);