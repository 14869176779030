import * as types from './constants';

export function signIn(credentails) {
  return {
    types: [
      types.LOAD_SIGNIN,
      types.LOAD_SIGNIN_SUCCESS,
      types.LOAD_SIGNIN_FAILURE
    ],
    promise: client => client.post(`/user/login`, credentails)
  };
}

export function signUp(credentails) {
  return {
    types: [
      types.LOAD_SIGNUP,
      types.LOAD_SIGNUP_SUCCESS,
      types.LOAD_SIGNUP_FAILURE
    ],
    promise: client => client.post(`/user/register`, credentails)
  };
}

export function loadUserData(user) {
  return {
    type: types.LOAD_USER_DATA,
    payload : user,
  }
} 

export function logout() {
  return {
    type: "USER_LOGOUT"
  }
} 

export function selfAPI(){
  return {
    types: [
      types.LOAD_SELF,
      types.LOAD_SELF_SUCCESS,
      types.LOAD_SELF_FAILURE
    ],
    promise: client => client.get(`/user/self`)
  };  
}

export function updateUserData(data) {
  return {
    types: [
      types.LOAD_UPDATE_USER_DATA,
      types.LOAD_UPDATE_USER_DATA_SUCCESS,
      types.LOAD_UPDATE_USER_DATA_FAILURE
    ],
    promise: client => client.put(`/user/update`, data)
  };  
}

export function updateProfileImage(imageFile) {
  return {
    types: [
      types.LOAD_UPDATE_PROFILE_IMAGE,
      types.LOAD_UPDATE_PROFILE_IMAGE_SUCCESS,
      types.LOAD_UPDATE_PROFILE_IMAGE_FAILURE
    ],
    promise: client => client.post(`/user/image`, imageFile)
  };  
}

export function verifyEmailAddress(data) {
  return {
    types: [
      types.LOAD_VERIFY_EMAIL,
      types.LOAD_VERIFY_EMAIL_SUCCESS,
      types.LOAD_VERIFY_EMAIL_FAILURE
    ],
    promise: client => client.post(`/user/activate`, data)
  };  
}

export function sendResetPasswordEmail(data) {
  return {
    types: [
      types.SEND_PASSWORD_RESET_EMAIL,
      types.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
      types.SEND_PASSWORD_RESET_EMAIL_FAILURE
    ],
    promise: client => client.post(`/user/password/request`, data)
  };  
}

export function resetPassword(data) {
  return {
    types: [
      types.SEND_PASSWORD_RESET_EMAIL,
      types.SEND_PASSWORD_RESET_EMAIL_SUCCESS,
      types.SEND_PASSWORD_RESET_EMAIL_FAILURE
    ],
    promise: client => client.post(`/user/password/reset`, data)
  };  
}
// other users actions

export function addNewUser(data) {
  return {
    types: [
      types.ADD_NEW_USER,
      types.ADD_NEW_USER_SUCCESS,
      types.ADD_NEW_USER_FAILURE
    ],
    promise: client => client.post(`/user/new-user`, data)
  };  
}

export function editAccountUser(data, id) {
  return {
    types: [
      types.UPDATE_ACCOUNT_USER,
      types.UPDATE_ACCOUNT_USER_SUCCESS,
      types.UPDATE_ACCOUNT_USER_FAILURE
    ],
    promise: client => client.put(`/user/account-user/${id}`, data)
  };  
}

export function deleteAccountUser(id) {
  return {
    types: [
      types.DELETE_ACCOUNT_USER,
      types.DELETE_ACCOUNT_USER_SUCCESS,
      types.DELETE_ACCOUNT_USER_FAILURE
    ],
    promise: client => client.delete(`/user/account-user/${id}`)
  };  
}

export function fetchUsersList(page, showLoader = true) {
  return {
    types: [
      types.FETCH_USERS_LIST,
      types.FETCH_USERS_LIST_SUCCESS,
      types.FETCH_USERS_LIST_FAILURE,
      !showLoader ? "NO_LOADER" : ""
    ],
    promise: client => client.get(`/user/users?page=${page}`)
  };  
}

export function fetchNotifications() {
  return {
    types: [
      "NO_LOADER"
    ],
    promise: client => client.get(`/notification`)
  };  
}





