import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as modalActions from "./actions";
import "./modal.scss";

const mapStateToProps = state => ({
  showModal: state.modal.showModal,
  modalContent: state.modal.modalContent
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...modalActions }),
  dispatch
});

function AlertDialog(props) {
  const onClick = (button) => {
    handleClose();
    button.action();
  }

  const handleClose = () => {
    props.dispatch(modalActions.hideModal());
  };

  return (
    <div>
      <Dialog
        open={props.showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="generic-modal"
      >
        <DialogContent>
          <h6>{props.modalContent.heading}</h6>
          <DialogContentText id="alert-dialog-description">
            {props.modalContent.message}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {
            props.modalContent.buttons.map((button, index) => {
              return (
                <Button onClick={() => onClick(button)} color="primary" key={index} variant={index === 0 && props.modalContent.buttons.length > 1 ? "outlined" : "contained" }>
                  {button.text}
                </Button>
              );
            })
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertDialog);