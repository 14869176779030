import * as types from './constants';

const initialState = {
  user: null,
  usersList: [],
  pagination: {
    page: 1,
    totalCount: 0
  },
  isLoggedIn: false
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_SIGNIN:
      return state;

    case types.LOAD_SIGNIN_SUCCESS:
      return {
        ...state,
        user: action.result.data.payload,
        isLoggedIn: true
      }

    case types.LOAD_USER_DATA:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: action.payload ? true : false
      };

    case types.LOAD_SIGNIN_FAILURE:
      return state;

    // for signup

    case types.LOAD_SIGNUP:
      return state;

    case types.LOAD_SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.result.data.payload,
        isLoggedIn: true
      }

    case types.LOAD_SIGNUP_FAILURE:
      return state;

    case types.LOAD_LOGOUT:
      return {
        ...state,
        user: null,
        isLoggedIn: false
      }

    // update user data
    case types.LOAD_UPDATE_USER_DATA:
      return state;

    case types.LOAD_UPDATE_USER_DATA_SUCCESS:
      return {
        ...state,
        user: action.result.data.payload
      };
    case types.LOAD_UPDATE_USER_DATA_FAILURE:
      return state;

    // update user profile image

    case types.LOAD_UPDATE_PROFILE_IMAGE:
      return state;

    case types.LOAD_UPDATE_PROFILE_IMAGE_SUCCESS:
      return {
        ...state,
        user: action.result.data.payload
      };
    case types.LOAD_UPDATE_PROFILE_IMAGE_FAILURE:
      return state;

    // verify Email address

    case types.LOAD_VERIFY_EMAIL:
      return state;

    case types.LOAD_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          emailVerified: true
        }
      };
    case types.LOAD_VERIFY_EMAIL_FAILURE:
      return state;

    // ADD NEW USER

    case types.ADD_NEW_USER:
      return state;

    case types.ADD_NEW_USER_FAILURE:
      return state;

    case types.ADD_NEW_USER_SUCCESS:
      return state;
    
    //fetch users list
      
    case types.FETCH_USERS_LIST_SUCCESS:      
      return {
        ...state,
        usersList: action.result.data.payload.docs,
        pagination: {
          page: action.result.data.payload.page,
          totalCount: action.result.data.payload.total
        } 
      };

    default:
      return state
  }
}

export default sessionReducer;