export const SHOW_MODAL = 'my-project/modal/SHOW_MODAL';
export const HIDE_MODAL = 'my-project/modal/HIDE_MODAL';
export const TOGGLE_USER_MODAL = 'my-project/modal/TOGGLE_USER_MODAL';
export const TOGGLE_WALKTHROUGH = 'my-project/modal/TOGGLE_WALKTHROUGH';
export const TOGGLE_CREATE_DOCUMENT_MODAL = 'my-project/modal/TOGGLE_CREATE_DOCUMENT_MODAL';
export const TOGGLE_SHARE_DOCUMENT_MODAL = 'my-project/modal/TOGGLE_SHARE_DOCUMENT_MODAL';
export const TOGGLE_PROFILE_MODAL = 'my-project/modal/TOGGLE_PROFILE_MODAL';
export const TOGGLE_SNACKBAR = 'my-project/modal/TOGGLE_SNACKBAR';
export const TOGGLE_DOCUMENT_DRAWER = 'my-project/modal/TOGGLE_DOCUMENT_DRAWER';
export const SHOW_LOADER = 'my-project/modal/SHOW_LOADER';
export const HIDE_LOADER = 'my-project/modal/HIDE_LOADER';





