import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import "./document-drawer.scss";
import DocumentSettingContent from "./documentSettingContent";
import PermissionsContent from "./permissionsContent";
import * as modalActions from "../Modal/actions";

const mapStateToProps = state => ({
  documentDrawer: state.modal.documentDrawer
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...modalActions }),
  dispatch
});

const headings = {
  document_settings: "Settings",
  permissions: "Permissions"
}

function DocumentDrawer(props) {
  const handleClose = () => {
    props.dispatch(modalActions.toggleDocumentDrawer())
  }

  return (
    <Drawer anchor={"right"} open={props.documentDrawer.open} onClose={handleClose} className="document-drawer-wrapper">
      <div className="drawer-header">
        <h6>{headings[props.documentDrawer.mode]}</h6>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      {
        props.documentDrawer.mode === "document_settings" && 
       <DocumentSettingContent data={props.documentDrawer.documentData} />
      }

      {
        props.documentDrawer.mode === "permissions" &&
        <PermissionsContent data={props.documentDrawer.documentData}/>
      }
    </Drawer>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDrawer);