import React from 'react';
import { connect } from 'react-redux';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1400,
    color: '#fff',
  },
}));

const mapStateToProps = state => ({
  showLoader: state.modal.showLoader,
});

function Loader(props) {
  const classes = useStyles();
  
  return (
    <div>
      <Backdrop className={classes.backdrop}  open={props.showLoader}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
}

export default connect(
  mapStateToProps,
)(Loader);