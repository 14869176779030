import * as types from './constants.js';

const initialState = {
  isLoading: false,
  mode: "landing"
};

const WorkspaceReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_WORKSPACE_FILES:
      return {
        ...state,
        isLoading: true
      };

    case types.GET_WORKSPACE_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case types.GET_WORKSPACE_FILES_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.CHANGE_WORKSPACE_MODE:
      return {
        ...state,
        mode: action.payload
      };

    default: return state;
  }
}

export default WorkspaceReducer;

