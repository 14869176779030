import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {
    Share as ShareIcon,
    EditOutlined as EditIcon,
    CreateNewFolderOutlined as CreateNewFolderOutlinedIcon,
    MoveToInbox as MoveFolderIcon,
    DeleteOutlineOutlined as DeleteIcon,
  } from "@material-ui/icons";


export const LOAD_FILES = "documents/LOAD_FILES";
export const LOAD_FILES_SUCCESS = "documents/LOAD_FILES_SUCCESS";
export const LOAD_FILES_FAILURE = "documents/LOAD_FILES_FAILURE";

export const UPDATE_FOLDER_LOCALLY = "documents/TOGGLE_FOLDER_RENAME";
export const SET_CURRENT_PATH = "documents/SET_CURRENT_PATH";
export const SET_CURRENT_FOLDER = "documents/SET_CURRENT_FOLDER";


export const LOAD_DOCUMENT_DETAILS = "documents/LOAD_DOCUMENT_DETAILS";
export const LOAD_DOCUMENT_DETAILS_SUCCESS = "documents/LOAD_DOCUMENT_DETAILS_SUCCESS";
export const LOAD_DOCUMENT_DETAILS_FAILURE = "documents/LOAD_DOCUMENT_DETAILS_FAILURE";

export const LOAD_DOCUMENT_DETAILS_CREATE_MODE = "documents/LOAD_DOCUMENT_DETAILS_CREATE_MODE";
export const LOAD_DOCUMENT_DETAILS_CREATE_MODE_SUCCESS = "documents/LOAD_DOCUMENT_DETAILS_CREATE_MODE_SUCCESS";
export const LOAD_DOCUMENT_DETAILS_CREATE_MODE_FAILURE = "documents/LOAD_DOCUMENT_DETAILS_CREATE_MODE_FAILURE";

export const LOAD_CREATE_DOCUMENT = "documents/LOAD_CREATE_DOCUMENT";
export const LOAD_CREATE_DOCUMENT_SUCCESS = "documents/LOAD_CREATE_DOCUMENT_SUCCESS";
export const LOAD_CREATE_DOCUMENT_FAILURE = "documents/LOAD_CREATE_DOCUMENT_FAILURE";

// folder tree

export const LOAD_FOLDER_TREE = "documents/LOAD_FOLDER_TREE";
export const LOAD_FOLDER_TREE_SUCCESS = "documents/LOAD_FOLDER_TREE_SUCCESS";
export const LOAD_FOLDER_TREE_FAILURE = "documents/LOAD_FOLDER_TREE_FAILURE";

// template tree

export const LOAD_TEMPLATES_TREE = "documents/LOAD_TEMPLATES_TREE";
export const LOAD_TEMPLATES_TREE_SUCCESS = "documents/LOAD_TEMPLATES_TREE_SUCCESS";
export const LOAD_TEMPLATES_TREE_FAILURE = "documents/LOAD_TEMPLATES_TREE_FAILURE";

// delete suggestions
export const DELETE_SUGGESTIONS = "documents/DELETE_SUGGESTIONS";
export const DELETE_SUGGESTIONS_SUCCESS = "documents/DELETE_SUGGESTIONS_SUCCESS";
export const DELETE_SUGGESTIONS_FAILURE = "documents/DELETE_SUGGESTIONS_FAILURE";

export const tableMenuDataForFolder = [
    // {
    //     icon: <ShareIcon />,
    //     text: "Share folder",
    // },
    {
        icon: <EditIcon />,
        text: "Rename",
        optionName: "rename",
    },
    {
        icon: <CreateNewFolderOutlinedIcon />,
        text: "Add new folder",
        optionName: "add_new_folder"
    },
    {
        icon: <MoveFolderIcon />,
        text: "Move folder",
        optionName: "move_folder",
    },
    {
        icon: <DeleteIcon />,
        text: "Delete folder",
        optionName: "delete_folder",
    },
];
export const tableMenuDataForDocument = [
    {
        icon: <LaunchOutlinedIcon />,
        text: "View document",
        optionName: "view_document",
    },
    {
        icon: <ShareIcon />,
        text: "Share document",
        optionName: "share_document",
    },
    {
        icon: <FavoriteBorderOutlinedIcon />,
        text: "Add as favourite",
        optionName: "add_as_fav",
    },
    {
        icon: <SettingsOutlinedIcon />,
        text: "Document settings",
        optionName: "settings",
    },
    {
        icon: <LockOutlinedIcon />,
        text: "Permissions",
        optionName: "permissions",
    },
    {
        icon: <DeleteIcon />,
        text: "Delete document",
        optionName: "delete_document",
    },
];

