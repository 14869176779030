import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import FlagIcon from '@material-ui/icons/Flag';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { profileImagePath, convertToNiceDate, splitBy_ } from "../../utils/utilServices";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#676767",
  },
}));

export default function RightDrawerCard(props) {
  const { mode, data } = props;
  const classes = useStyles();
  const [buttons, setButtons] = React.useState([]);
  const [reason, setReason] = React.useState("");
  const [cardData, setCardData] = React.useState({
    username: "",
    userId: "",
    showText: false,
    suggestionText: "",
    suggestionImage: "",
    suggestionVideo: "",
  });

  useEffect(() => {
    getButtons();
  }, [cardData]);

  useEffect(() => {
    if (mode === "flag") {
      setCardData({
        username: data.addedBy?.firstName + ' ' + data.addedBy?.lastName,
        userId: data.addedBy?._id,
        showText: false,
        suggestionText: data.type === "text" ? data.content : "",
        suggestionImage: data.type === "image" ? data.imageUrl : "",
        suggestionVideo: data.type === "videourl" ? data.videoUrl : ""
      })
    } else {
      setCardData({
        username: data.createdBy?.firstName + ' ' + data.createdBy?.lastName,
        userId: data.createdBy?._id,
        showText: data.type === "suggestion" && data.content.suggestion.type === "text",
        suggestionText: data.type === "suggestion" && data.content.suggestion.type === "text" ? data.content.suggestion.content : "",
        suggestionImage: data.type === "suggestion" && data.content.suggestion.type === "image" ? data.content.suggestion.imageUrl : "",
        suggestionVideo: data.type === "suggestion" && data.content.suggestion.type === "videourl" ? data.content.suggestion.videoUrl : ""
      })
    }

  }, [data])

  const getCardContent = () => {
    switch (mode) {
      case "document_access": return `${cardData.username} has requested access to this document.`
      case "document_edit_access": return `${cardData.username} has requested edit access to this document.`
      case "document_access_approved": return `${cardData.username} has approved your request access to this document.`
      case "document_access_rejected": return `${cardData.username} has rejected your request access to this document.`
      case "assignment": return `${cardData.username} requested you to complete a section in this document.`
      case "comment": return `${cardData.username} commented: "${data.content.comment}".`
      case "suggestion": return `${cardData.username} is suggesting the following content -`
      case "suggestion_approved": return `
      ${cardData.username} approved your suggestion.
      ${data.content.reason ? `Reason: "${data.content.reason}"` : ""}
      `
      case "suggestion_rejected": return `
      ${cardData.username} rejected your suggestion.
      ${data.content.reason ? `Reason: "${data.content.reason}"` : ""}
      `
      case "assignment_complete": return `${cardData.username} has marked the above section as completed.`
      case "compliance": return `You are required to read and acknowledge this document.`
      case "compliance_marked": return `${cardData.username} has read and acknowledged this document.`
      default: return "";
    }
  }

  const getButtons = () => {
    switch (mode) {
      case "document_access":
      case "document_edit_access":
      case "suggestion":
      case "flag":
        setButtons([
          {
            name: "decline",
            icon: <ClearIcon className="button-icon" />
          },
          {
            name: "approve",
            icon: <CheckIcon className="button-icon" />
          }
        ]); break;

      case "document_access_approved":
        setButtons([
          { name: "open_document" }
        ]); break;

      case "document_access_rejected":
      case "suggestion_approved":
      case "suggestion_rejected":
        setButtons([
          { name: "discard" }
        ]); break;

      case "assignment":
        setButtons([
          { name: "mark_as_complete" },
          { name: "view" }
        ]); break;

      case "comment":
      case "assignment_complete":
        setButtons([
          { name: "discard" },
          { name: "view" }
        ]); break;
      case "compliance":
      case "compliance_marked":
        setButtons([
          { name: "open_document" }
        ]); break;
      default: break;
    }
  }

  const handleClick = (buttonName) => {
    let content = undefined;
    if (mode === "suggestion" && buttonName === "approve") {
      if (cardData.suggestionImage) {
        content = `<img src=${cardData.suggestionImage} width="250" alt="" class="fr-fic fr-dii fr-draggable"/>`
      } else if (cardData.suggestionText) {
        content = `<p>${cardData.suggestionText}</p>`
      } else if (cardData.suggestionVideo) {
        content = `
        <video controls id="video-preview">
          <source src=${cardData.suggestionVideo}
            type="video/webm" />

          <source src=${cardData.suggestionVideo}
            type="video/mp4" id="video-src" />

          Sorry, your browser doesn't support embedded videos.
        </video>
      `
      }
    }

    let payload = {
      content,
      reason
    }

    props.onClick(buttonName, data, payload)
  }

  return (
    <Card className={`drawer-card-wrapper`}>
      <CardHeader className="card-header-wrap"
        avatar={
          <Avatar alt={cardData.username} src={profileImagePath(cardData.userId)} aria-label="recipe" className={classes.avatar} />
        }
        action={
          mode === "suggestion" &&
          <Tooltip title={"Flag suggestion"} aria-label="add doc">
            <IconButton aria-label="settings" className="flag-button" onClick={() => props.onClick("flag", data, reason)}>
              <FlagIcon />
            </IconButton>
          </Tooltip>
        }
        title={mode === "flag" ? "suggestion" : splitBy_(mode)}
        subheader={`${cardData.username} - ${convertToNiceDate(data.createdAt)}`}
      />


      <CardContent className="card-content-wrap">
        {
          data.content?.documentTitle &&
          <p className="doc-details">Document - {data.content.documentTitle}</p>
        }

        {
          data.content?.section &&
          <p className="doc-details">Section #{data.content.section}</p>
        }

        <p className="desc">
          {getCardContent()}
        </p>

        {
          cardData.suggestionText &&
          <p className="text-suggestion">
            {cardData.suggestionText}
          </p>
        }

        {
          cardData.suggestionImage &&
          <CardMedia
            image={cardData.suggestionImage}
            title=""
            className="card-media-wrap"
          />}

        {
          cardData.suggestionVideo &&
          <CardMedia
            component="video"
            image={cardData.suggestionVideo}
            title=''
            controls
            className="card-video"
          />
        }

        {
          ["suggestion", "comments", "flag"].includes(mode) &&
          <TextField label="Comments" value={reason}
            onChange={e => setReason(e.target.value)}
            variant="outlined" className="comment-input" />
        }
      </CardContent>

      <CardActions>
        {
          buttons.map((button, index) => {
            return (
              <Button size="small" className="action-button" key={index} onClick={() => handleClick(button.name)}>
                {button.icon ? button.icon : ""}
                <span>{splitBy_(button.name)}</span>
              </Button>
            );
          })
        }
      </CardActions>
    </Card>
  );
}

RightDrawerCard.propTypes = {
  mode: PropTypes.string // oneOf("access", "suggestions", "assginments", "complaince", "comments")
};

RightDrawerCard.defaultProps = {
  data: {}
}