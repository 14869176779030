import * as types from './constants.js';

export function fetchFoldersAndDocuments(id) {
  return {
    types: [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE
    ],
    updateCurrentFolder: true,
    promise: client => client.get(`/folder/getFoldersAndDocuments/${id ? id : ""}`)
  };
}

export function updateFolderLocally(folder) {
  return {
    type: types.UPDATE_FOLDER_LOCALLY,
    folder
  }
}

export function updateFolder(data) {
  return {
    types: [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.post(`/folder/updateFolder`, data)
  };
}

export function updateDocument(data, documentId, returnType) {
  return {
    types: !returnType ? [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ] : [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.post(`/document/updateDocument/${documentId}${!returnType ? "" : returnType}`, data)
  };
}

export function moveDocumentToFolder(folderId, documentId) {
  return {
    types: [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ],
    updateCurrentFolder: true,
    folderId,
    documentId,
    promise: client => client.post(`/document/updateDocument/${documentId}`, { folderId: folderId })
  };
}

export function moveFolder(folderId, parent) {
  return {
    types: [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ],
    updateCurrentFolder: true,
    folderId: parent,
    documentId: folderId,
    promise: client => client.post(`/folder/updateFolder`, {parent, folderId})
  };
}

export function createNewFolder(data) {
  return {
    types: [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ],
    updateCurrentFolder: true,
    promise: client => client.post(`/folder/createFolder`, data)
  };
}

export function deleteFolder(id) {
  return {
    types: [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.delete(`/folder/${id}`)
  };
}

export function deleteDocument(id, returnType) {
  return {
    types: !returnType ? [
      types.LOAD_FILES,
      types.LOAD_FILES_SUCCESS,
      types.LOAD_FILES_FAILURE,
      "NO_LOADER"
    ] : [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
    ],
    promise: client => client.delete(`/document/${id}${returnType ? returnType : ""}`)
  };
}

export function getDocument(id) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS_CREATE_MODE,
      types.LOAD_DOCUMENT_DETAILS_CREATE_MODE_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_CREATE_MODE_FAILURE,
    ],
    promise: client => client.get(`/document/${id}`)
  };
}

export function createDocument(data, isTemplate, templateId) {
  let params = "";

  if (isTemplate && templateId) {
    params = `?type=template&templateId=${templateId}`;
  } else if (templateId) {
    params = `?templateId=${templateId}`;
  } else if (isTemplate) {
    params = `?type=template`;
  }
  

  return {
    types: [
      types.LOAD_CREATE_DOCUMENT,
      types.LOAD_CREATE_DOCUMENT_SUCCESS,
      types.LOAD_CREATE_DOCUMENT_FAILURE
    ],
    promise: client => client.post(`/document/createDocument${params}`, data)
  };
}

export function createSection(documentId) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.post(`/section/createSection`, documentId)
  };
}

export function deleteSection(sectionId, documentId) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.delete(`/section/${documentId}/${sectionId}`)
  };
}

export function updateSection(sectionId, content) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.put(`/section/${sectionId}`, content)
  };
}

export function updateSectionContent(data) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
      "NO_LOADER"
    ],
    promise: client => client.post(`/section/content`, data)
  };
}

export function setCurrentPath(paths) {
  return {
    type: types.SET_CURRENT_PATH,
    payload: paths
  }
}

export function setCurrentFolder(folderDetails) {
  return {
    type: types.SET_CURRENT_FOLDER,
    payload: folderDetails
  }
}

// document actions

export function publishDocument(data, documentId) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
    ],
    promise: client => client.post(`/document/publish/${documentId}`, data)
  };
}

export function getPublishedDocument(documentId, passcode) {
  return {
    types: [
      types.LOAD_DOCUMENT_DETAILS,
      types.LOAD_DOCUMENT_DETAILS_SUCCESS,
      types.LOAD_DOCUMENT_DETAILS_FAILURE,
    ],
    promise: client => client.get(`/document/published/${documentId}${passcode ? "?passcode="+passcode : "" }`)
  };
}

export function getFolderStructure() {
  return {
    types: [
      types.LOAD_FOLDER_TREE,
      types.LOAD_FOLDER_TREE_SUCCESS,
      types.LOAD_FOLDER_TREE_FAILURE
    ],
    promise: client => client.get(`/folder`)
  }
}

export function getTemplateFolderStructure() {
  return {
    types: [
      types.LOAD_TEMPLATES_TREE,
      types.LOAD_TEMPLATES_TREE_SUCCESS,
      types.LOAD_TEMPLATES_TREE_FAILURE
    ],
    promise: client => client.get(`/folder/template-folder`)
  }
}

export function saveAsCopy(data, isTemplate) {
  return {
    types: ["NO_LOADER"],
    promise: client => client.post(`/document/copy${isTemplate ? "?type=template" : ""}`, data)
  }
}

export function getAllTemplates() {
  return {
    types: ["NO_LOADER"],
    promise: client => client.get(`/folder/templates`)
  }
}

export function checkDocumentPublishStatus(documentId) {
  return {
    types: [""],
    promise: client => client.get(`/document/published-status/${documentId}`)
  }
}
