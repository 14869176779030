import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import configureStore from "./utils/configureStore";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';

export const AuthButton = withStyles((theme) => ({
  root: {
    color: "#ffffff",
    backgroundColor: "#35B34E",
    '&:hover': {
      backgroundColor: "rgba(53,179,78,0.8)",
    },
  },
}))(Button);

const { store } = configureStore();

const theme = createMuiTheme({
  
  palette: {
    text: {
      primary: "#595959"
    },
    primary: {
      main: "#3085f6",
    },
    secondary: {
      main: "#e34690",
    },
  },

  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif'
    ].join(",")
  }

});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
