import * as types from './constants';

const initialState = {
  showLoader: false,
  showModal: false,
  modalContent: {
    heading: '',
    message: '',
    type: '',
    buttons: [
      {
        text: 'Cancel',
        action: () => { }
      },
      {
        text: 'OK',
        action: () => { }
      }
    ]
  },
  showAddNewUserModal: false,
  editUser: null,
  showWalkthrough: false,
  showDocumentCreateModal: false,
  shareModal: {
    showShareDocumentModal: false,
    documentId: ""
  },
  showProfileModal: false,
  snackBar: {
    open: false,
    message: "",
    button: {
      name: "Dismiss",
      action: () => {}
    }
  },
  documentDrawer: {
    open: false,
    mode: "",
    documentData: null
  }
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      return {
        ...state,
        showLoader: true
      }

    case types.HIDE_LOADER:
      return {
        ...state,
        showLoader: false
      }

    case types.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        modalContent: action.content
      };

    case types.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        modalContent: initialState.modalContent
      };

    case types.TOGGLE_USER_MODAL:
      return {
        ...state,
        showAddNewUserModal: !state.showAddNewUserModal,
        editUser: action.payload
      };

    case types.TOGGLE_WALKTHROUGH:
      return {
        ...state,
        showWalkthrough: !state.showWalkthrough
      };

    case types.TOGGLE_CREATE_DOCUMENT_MODAL:
      return {
        ...state,
        showDocumentCreateModal: !state.showDocumentCreateModal
      };

    case types.TOGGLE_SHARE_DOCUMENT_MODAL:
      return {
        ...state,
        shareModal: {
          showShareDocumentModal: !state.shareModal.showShareDocumentModal,
          documentId: action.payload
        }
      };

    case types.TOGGLE_PROFILE_MODAL:
      return {
        ...state,
        showProfileModal: !state.showProfileModal
      };

    case types.TOGGLE_SNACKBAR:
      return {
        ...state,
        snackBar: {
          open: !action.payload.message ? false : true,
          message: action.payload.message,
          button: action.payload.button ? action.payload.button : initialState.snackBar.button
        }
      };

    case types.TOGGLE_DOCUMENT_DRAWER:
      return {
        ...state,
        documentDrawer: {
          open: !state.documentDrawer.open,
          mode: action.mode,
          documentData: action.documentData
        }
      };
    default:
      return state;
  }
};

export default modal;

