import * as types from './constants.js';

const initialState = {
  files: [],
  documentree: {},
  folderTree: null, //for save as copy
  templateTree: null, //for save as template
  currentFolder: null,
  currentPath: [],
  currentDocument: null,
  accesingUserDetails: {
    role: "",
    settings: {}
  },
  isLoading: false,
  error: ''
};

const DocumentReducer = (state = initialState, action) => {
  let result = [];
  switch (action.type) {
    case types.LOAD_FILES:
      return {
        ...state,
        isLoading: true
      };

    case types.LOAD_FILES_SUCCESS:
      result = action.result || [];
      let folders = result.data.payload.children.map((folder) => {
        folder.editName = false;
        return folder;
      });
      let documents = result.data.payload.documents;

      let updatedDocumentTree = { ...state.documentree };

      if (action.folderId && action.documentId) { //remove from current folder
        updatedDocumentTree[state.currentFolder.id] = state.documentree[state.currentFolder.id].filter(file =>
          file._id !== action.documentId
        )
      }

      return {
        ...state,
        isLoading: false,
        files: folders.concat(documents),
        documentree: {
          ...updatedDocumentTree,
          [result.data.payload._id]: folders.concat(documents),
        },
        currentFolder: action.updateCurrentFolder ? {
          id: result.data.payload._id,
          name: result.data.payload.name
        } : state.currentFolder,
      };

    case types.LOAD_FILES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case types.UPDATE_FOLDER_LOCALLY:
      let updatedFiles = state.documentree[state.currentFolder.id].map(folder => {
        if (folder._id === action.folder._id) {
          return action.folder;
        } else {
          return folder;
        }
      });

      return {
        ...state,
        files: updatedFiles,
        documentree: {
          ...state.documentree,
          [state.currentFolder.id]: updatedFiles
        }
      };

    case types.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload
      }

    case types.SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload
      }

    case types.LOAD_DOCUMENT_DETAILS:
      return {
        ...state,
        isLoading: true
      };

    case types.LOAD_DOCUMENT_DETAILS_SUCCESS:
      return {
        ...state,
        currentDocument: action.result.data.payload,
        isLoading: false
      }
    case types.LOAD_DOCUMENT_DETAILS_FAILURE:
      return {
        ...state,
        currentDocument: action.error,
        isLoading: false
      };

    case types.LOAD_DOCUMENT_DETAILS_CREATE_MODE_SUCCESS:
      return {
        ...state,
        currentDocument: action.result.data.payload.document,
        accesingUserDetails: {
          role: action.result.data.payload.role,
          settings: action.result.data.payload.settings
        },
        isLoading: false
      }

    case types.LOAD_DOCUMENT_DETAILS_CREATE_MODE_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentDocument: action.error
      }

    case types.LOAD_CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentree: {
          ...state.documentree,
          [state.currentFolder.id]:
            [...state.documentree[state.currentFolder.id], action.result.data.payload]
        }
      }

    case types.LOAD_FOLDER_TREE_SUCCESS:
      return {
        ...state,
        folderTree: action.result.data.payload
      }

    case types.LOAD_TEMPLATES_TREE_SUCCESS:
      return {
        ...state,
        templateTree: action.result.data.payload
      }

    case types.DELETE_SUGGESTIONS:
      return state;

    case types.DELETE_SUGGESTIONS_SUCCESS:
      let updatedDoc = state.currentDocument;
      let sectionIndex = updatedDoc.sections.findIndex(e => e._id === action.section)
      updatedDoc.sections[sectionIndex].suggestions = action.result.data.payload.suggestions

      return {
        ...state,
        currentDocument: {...state.currentDocument, document: updatedDoc}
      }

    case types.DELETE_SUGGESTIONS_FAILURE:
      return state

    default:
      return state;
  }
};

export default DocumentReducer;
