import React, { useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as actions from "../../modules/Documents/actions";
import * as docUserActions from "../../modules/CreateDocument/actions";
import { isEmptyObj, profileImagePath } from "../../utils/utilServices";


const permissionTypes = [
  {
    heading: "everyone",
    desc: "Can be accessed by all readers if published, and all authors if unpublished."
  },
  {
    heading: "individual",
    desc: "Can only be accessed by individuals who have permission to this document"
  }
]

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});


function PermissionsContent({ data, dispatch }) {
  const location = useLocation();
  const permissionsRef = useRef();

  const [permissionChecked, setPermissionchecked] = React.useState(data.permissions.everyone ? "everyone" : "individual");
  const [users, setUsers] = React.useState([]);
  const [assignedUsers, setAssignedUsers] = React.useState(data.permissions.users);


  useEffect(() => {
    permissionsRef.current = {
      everyone: permissionChecked === "everyone" ? true : false,
      users: assignedUsers
    };
  }, [permissionChecked, assignedUsers]);

  useEffect(() => {
    return () => {
      let currentData = permissionsRef.current;

      let updatedData = {
        everyone: currentData.everyone !== data.permissions.everyone ? currentData.everyone : undefined,
        users: !checkIfArrayEqual(data.permissions.users, currentData.users) ?
          (currentData.everyone ? [] : currentData.users.map((user) => user._id)) : undefined
      }

      if (!isEmptyObj(updatedData)) {
        dispatch(actions.updateDocument({ permissions: updatedData }, data._id, getReturnType()))
        .then(res => {
          if (res.data.success && location.pathname.includes("create-document")) {
            updateParentWindow();
          }
        });
      }
    }
  }, []);

  const updateParentWindow = () => {
    if (window.opener?.location.pathname === "/documents") {
      window.opener?.location.reload();
    }
  }

  const checkIfArrayEqual = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b)
  }

  const getReturnType = () => {
    let type = "";
    ["create-document", "document/"].map(item => {
      if (location.pathname.includes(item)) {
        type = "/?returnType=document";
      }
    })

    return type;
  }

  const onUsersSearchInputChange = (e, val, reason) => {
    if (reason === "input") {
      dispatch(docUserActions.searchUser(val))
        .then(res => {
          if (res.data.success) {
            setUsers(res.data.payload.docs)
          }
        })
    }
  }

  const deleteUser = (user) => {
    setAssignedUsers(assignedUsers.filter(u => u._id !== user._id));
  }

  const addUser = (val) => {
    if (assignedUsers.findIndex(e => e._id === val._id) === -1) {
      setAssignedUsers([...assignedUsers, val])
    }
  }

  return (
    <div className="permissions-content-wrapper">
      <div className="radios-wrapper">
        {
          permissionTypes.map((item, index) => {
            return (
              <div className="publish-type-box" key={index}>
                <Radio
                  checked={permissionChecked === item.heading}
                  onChange={(e) => setPermissionchecked(e.target.value)}
                  value={item.heading}
                  color="primary"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'D' }}
                />

                <div>
                  <p className="caption">{item.heading}</p>
                  <p className="subtitle-small">
                    {item.desc}
                  </p>
                </div>
              </div>
            );
          })
        }
      </div>

      {permissionChecked === "individual" &&
        <div className="assignee-autocomplete-wrap">
          <Autocomplete
            id="combo-box-demo"
            filterOptions={(options, state) => options}
            options={users}
            getOptionLabel={(option) => option ? `${option.firstName} ${option.lastName}` : ""}
            // style={{ width: 280, margin: "0 auto" }}
            onChange={(e, val) => val ? addUser(val) : {}}
            onInputChange={(e, val, reason) => onUsersSearchInputChange(e, val, reason)}
            renderOption={(option) => (
              <ListItem className="user-list-item">
                <ListItemAvatar>
                  <Avatar alt={`${option.firstName} ${option.lastName}`} src={profileImagePath(option.profile)} className="user-avatar" />
                </ListItemAvatar>
                <ListItemText primary={`${option.firstName} ${option.lastName}`} secondary={option.email} />
              </ListItem>
            )}
            renderInput={(params) => <TextField {...params} placeholder="Add people" variant="outlined" />}
          />

          <List>
            {
              assignedUsers.map((user, index) => {
                return (
                  <ListItem className="user-list-item">
                    <ListItemAvatar>
                      <Avatar alt={`${user.firstName} ${user.lastName}`} src={profileImagePath(user.profile)} className="user-avatar" />
                    </ListItemAvatar>
                    <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => deleteUser(user)}>
                        <HighlightOffIcon className="user-list-item-delete-icon" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
            }
          </List>
        </div>}
    </div>
  );
}

export default connect(
  mapDispatchToProps
)(PermissionsContent);