import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import "./tabs.scss";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${props.id}panel-${index}`}
      aria-labelledby={`${props.id}-${index}`}
      {...other}
    >
      {/* {value === index && ( */}
        <Box paddingTop={2}>
          <Typography>{children}</Typography>
        </Box>
      {/* )} */}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "1px solid #f7ebeb"
  },
}));

export default function SimpleTabs(props) {
  const classes = useStyles();

  function a11yProps(index) {
    return {
      id: `${props.id}-${index}`,
      'aria-controls': `${props.id}panel-${index}`,
    };
  }

  return (
    <div className={classes.root}>
      <Tabs value={props.activeTab} onChange={props.handleTabChange} aria-label="simple tabs example" className="tabs-wrapper">
        {
          props.tabNames.map((name, index) => {
            return (
              <Tab label={name} {...a11yProps(index)} key={index} className="tab-item"/>
            );
          })
        }
      </Tabs>
    </div>
  );
}

SimpleTabs.propTypes = {
  id: PropTypes.string,
  tabNames: PropTypes.array,
  activeTab: PropTypes.number,
  handleTabChange: PropTypes.func
}