import * as types from './constants';

const initialState = {
    isLoading: false
};

const notify = (state = initialState, action) => {
    switch (action.type) {
        case types.NOTIFICATION_ACTION:
            return {
                ...state,
                isLoading: true
            };
        case types.NOTIFICATION_ACTION_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case types.NOTIFICATION_ACTION_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
};

export default notify;

