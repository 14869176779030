import * as types from './constants';

export function showModal(content) {
    return {
        type: types.SHOW_MODAL,
        content: content
    }
}

export function hideModal() {
    return {
        type: types.HIDE_MODAL
    }
}

export function toggleUserModal(user) {
    return {
        type: types.TOGGLE_USER_MODAL,
        payload: user
    }
}

export function toggleWalkthrough() {
    return {
        type: types.TOGGLE_WALKTHROUGH
    }
}

export function toggleCreateDocumentModal() {
    return {
        type: types.TOGGLE_CREATE_DOCUMENT_MODAL
    }
}

export function toggleShareDocumentModal(documentId) {
    return {
        type: types.TOGGLE_SHARE_DOCUMENT_MODAL,
        payload: documentId
    }
}

export function toggleProfileModal() {
    return {
        type: types.TOGGLE_PROFILE_MODAL
    }
}

export function toggleSnackbar(message, button) {
    return {
        type: types.TOGGLE_SNACKBAR,
        payload: {
            message,
            button
        }
    }
}

export function toggleDocumentDrawer(mode, documentData) {
    return {
        type: types.TOGGLE_DOCUMENT_DRAWER,
        mode,
        documentData
    }
}

export function showLoader() {
    return {
        type: types.SHOW_LOADER,
    }
}

export function hideLoader() {
    return {
        type: types.HIDE_LOADER,
    }
}


