
import { SUCCESS_DEFAULT, FAILURE_DEFAULT } from './constants';
import { Util } from './util';
import { showLoader, hideLoader } from "../components/Modal/actions";

export default function clientMiddleware(client) {
  
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }
      const { promise, types, ...rest } = action; // eslint-disable-line no-redeclare
      if (!promise) {
        return next(action);
      }
      client.defaults.headers = authHeader();
      
      if (!types.includes("NO_LOADER")) {
        dispatch(showLoader());
      }

      const [
        REQUEST,
        SUCCESS = SUCCESS_DEFAULT,
        FAILURE = FAILURE_DEFAULT
      ] = types;
      next({ ...rest, type: REQUEST });

      const actionPromise = promise(client);
      actionPromise
        .then(
          result => {
              dispatch(hideLoader());
            if ((result && result.data && result.data.success && result.data.success === true) || !(result.data.error)) {
              next({ ...rest, result, type: SUCCESS });
            }else  if (result && result.data && result.data.error) {
              // let error = {
              //     message: result.data.error.errorMessage
              // }
              let error = result.data.error.errorMessage;
              next({ ...rest, error, type: FAILURE });
            }else {  
              let error = {
                message: "Unexpected error occured, try again later"
              }           
              next({ ...rest, error, type: FAILURE });
            }
          },
          error => {
              dispatch(hideLoader());
            error.message = "Unexpected error occured, try again later";
            next({ ...rest, error, type: FAILURE });
          }
        )
        .catch(error => {
          dispatch(hideLoader());
          // eslint-disable-next-line no-console
          console.error('MIDDLEWARE ERROR:', error);
          // next({...rest, error, type: FAILURE});
        });

      return actionPromise;
    };
  };
}

export function authHeader() {
  return Util.getAuthToken() ? 
  { 'authorization': Util.getAuthToken() } : {};
}
