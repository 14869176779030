export const baseUrl = "http://185.155.104.119/api/";
export const SUCCESS_DEFAULT = 'SUCCESS_DEFAULT';
export const FAILURE_DEFAULT = 'FAILURE_DEFAULT';
export const USER_LOCKED = 'USER_LOCKED';

export const defaultPatterns = {
  name: "[A-Za-z0-9_ ]{1,}",
  email:
    "[a-zA-Z0-9!#.$%&*-+/=?^_{|}~-]{1,}@[a-zA-Z0-9!#$%&*-+/=?^_{|}~-]{1,}([.]{1}[a-zA-Z0-9!#$%&*-+/=?^_{|}~-]{1,}){1,}",
  password:
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*)(?=.*[@$!%*?&])[A-Za-z][A-Za-z0-9!@#$%^&*()_+]{7,}$",
}

export const errorMessages = {
  required: "Required field cannot be empty",
  invalid: "Please enter a valid ",
  emailAlreadyUsed: 'This email address is already registered. ' +
  'This may be due to exiting the signup process early. To complete the process please sign in.',
  passwordError: 'Wrong password',
  emailNotFound: 'Email does not exist',

  EMAIL_NOT_FOUND: "Invalid email address",
  ACTIVATIONKEY_EXPIRED: "Activation key has expired",
  INVALID_DETAILS: "Invalid email or token",
  ALREADY_ADDED_USER: "This user has already been added to your account."
}