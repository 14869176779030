import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import "./filler.scss";

export default function Filler() {
  return (
    <div className="fillers-wrapper">
      <Grid container spacing={3}>
        <Grid item xs={false} sm={1} className="side-bar">
          {
            Array(5).fill(20).map((item, index) => {
              return (
                <div className="menu-item-mimic" key={index}>
                  <Skeleton variant="circle" width={40} height={40} className="circle"/>
                  <Skeleton variant="text" className="small-text"/>
                </div>
              )
            })
          }
        </Grid>

        <Grid item xs={12} sm={11}>
          {
            Array(12).fill(20).map((item, index) => {
              return (<Skeleton variant="text" className="text" key={index} />)
            })
          }
        </Grid>
      </Grid>
    </div>
  );
}