import * as types from './constants.js';

const initialState = {
  media: []
};

const MediaReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.LOAD_MEDIA_SUCCESS:
      return {
        ...state,
        media: action.result.data
      };
    
    case types.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        media: state.media.filter(img => img.id !== action.id)
      };
    
    case types.UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        media: [...state.media, action.result.data]
      }
    
    default: return state;
  }
}

export default MediaReducer;

