import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import * as actions from "../../modules/Signin/sessionActions";
import * as modalActions from "../Modal/actions";
import { profileImagePath } from "../../utils/utilServices";
import ProfileDialog from "./profileModal";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});

const avatarImgSrc = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDH-NEOuZMgTRq2VtmclvBel5Vna_0ohGNFw&usqp=CAU";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);


function UserDropdown(props) {
  const history = useHistory();
  const { user } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openProfileModal = () => {
    handleClose();
    props.dispatch(modalActions.toggleProfileModal());
  }

  const logout = () => {
    handleClose();
    props.dispatch(actions.logout());
    localStorage.clear();
    history.push("/signin");
  }

  return (
    <div>
      {
        user.profileImageUploaded ?
          <Avatar onClick={handleClick} aria-controls="customized-menu" aria-haspopup="true" src={profileImagePath(user._id) + '?' + new Date().getTime()} className="user-avatar" />
          :
          <Avatar onClick={handleClick} aria-controls="customized-menu" aria-haspopup="true" className="user-avatar">
            {user.firstName.charAt(0)}{user.lastName.charAt(0)}
          </Avatar>
      }

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="user-dropdown"
      >
        <StyledMenuItem>
          <ListItem>
            <ListItemAvatar>
              {
                user.profileImageUploaded ?
                  <Avatar src={profileImagePath(user._id) + '?' + new Date().getTime()} />
                  :
                  <Avatar>
                    {`${user.firstName.charAt(0)} ${user.lastName.charAt(0)}`}
                  </Avatar>
              }
            </ListItemAvatar>
            <ListItemText primary={`${user.firstName} ${user.lastName}`} secondary={user.email} />
          </ListItem>
        </StyledMenuItem>

        <div className="user-buttons">
          <Button color="primary" onClick={openProfileModal}>Profile</Button>
          <Button color="primary" onClick={logout}>Log out</Button>
        </div>

        {/* For later - users will be given in accounts key */}
        {/* {
          top100Films.map((item, index) => {
            return (
              <StyledMenuItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.title} secondary="Jan 9, 2014" />
                </ListItem>
              </StyledMenuItem>
            );
          })
        } */}
      </StyledMenu>

      <ProfileDialog />
    </div>
  );
}

export default connect(
  mapDispatchToProps
)(UserDropdown);