import React from 'react';
import './App.scss';
import "./styles/base.scss";
import "./styles/styles.css";
import { connect } from 'react-redux';
import Tour from 'reactour';

import { simpleAction } from './actions/simpleAction';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import routes from "./routes";

import Header from "./components/Header";
import AlertDialog from "../src/components/Modal";
import AddNewUserDialog from "../src/components/Modal/addNewUserModal";
import ChooseDocumentTemplateDialog from "../src/components/Modal/chooseDocumentTemplateModal";
import ShareDocumentDialog from "../src/components/Modal/shareDocument";
import FlipSnackbar from "../src/components/Snackbar";
import DocumentDrawer from "../src/components/DocumentDrawer";
import Loader from "../src/components/Loader";

import { useEffect } from 'react';
import * as actions from "./modules/Signin/sessionActions";
import * as modalActions from "./components/Modal/actions";
import * as workspaceActions from "./modules/Workspace/actions";
import { Util } from './utils/util';
import { bindActionCreators } from 'redux';

const mapStateToProps = state => ({
  ...state,
  user: state.session.user,
  isLoggedIn: state.session.isLoggedIn,
  showWalkthrough: state.modal.showWalkthrough
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});


function WalkthroughModal(props) {
  const {heading, content} = props.data;
  
  return(
    <div>
      <h6>{heading}</h6>
      <p>{content}</p>
    </div>
  );
}

function App(props) {
  const location = useLocation();
  const [disableBack, setDisableBack] = React.useState(true);
  const [steps, setSteps] = React.useState([]);
  const [isHeader, setIsHeader] = React.useState(true);

  useEffect(()=>{
    setIsHeader(showHeader());
  }, [location])
  
  useEffect(()=>{
    props.dispatch(actions.selfAPI()).then((res)=> {
      if (res.data.success) {
        let user = res.data.payload;
        props.dispatch(actions.loadUserData(user));
      }
    })

    setSteps([
      {
        selector: window.screen.width < 1280 ? '#create-doc-mobile-button' : '#create-doc-header-button', //desktop button is #create-doc-header-button
        content: <WalkthroughModal data={{
          heading: "Create document",
          content: "Use the create document button to start creating and publishing your first document",
        }}/>,
      },
      {
        selector: window.screen.width < 600 ? '#add-person-to-team-mobile' : '#add-person-to-team' , //desktop button is #add-person-to-team
        content: <WalkthroughModal data={{
          heading: "Invite your team",
          content: "Invite your team and start collaborating on your documents",
        }}/>,
      },
      {
        selector: window.screen.width < 1280 ? '#drawer-items-mobile' : '#drawer-items' , //desktop button is drawer-items
        content: <WalkthroughModal data={{
          heading: "Navigation",
          content: "Navigate your way through the application using the side menu",
        }}/>,
      },
      {
        selector: '#workspace-center-anchor',
        content: <WalkthroughModal data={{
          heading: "User guide",
          content: <p>Still need some more help? Visit our user guide <Link href='#' onClick={e => e.preventDefault()}>here</Link></p>,
        }}/>,
      }
    ]);
  }, [])

  const simpleButtonAction = (event) => {
    props.simpleAction();
  }

  const closeWalkthrough = () => {
    props.dispatch(modalActions.toggleWalkthrough());
  }

  const showHeader = () => {
    let showHeader = true;
    ["create-document", "document/"].map(item => {
      if (location.pathname.includes(item)) {
        showHeader = false
      }
    })

    return showHeader;
  }

  const updateTutorialFlag = () => {
    props.dispatch(workspaceActions.changeWorkspaceMode("table"));
  }

  return (
    <div className="App">
      {isHeader && <Header /> }
      <div className={`app-container ${props.isLoggedIn && isHeader ? "move-right" : (!isHeader && props.isLoggedIn ? "small-top" : "")}`}>
        {routes}
      </div>
      <AlertDialog />
      <AddNewUserDialog />
      <ChooseDocumentTemplateDialog />
      <ShareDocumentDialog />
      <FlipSnackbar />
      <DocumentDrawer />
      <Loader />

      {<Tour
        steps={steps}
        isOpen={props.showWalkthrough}
        accentColor={"#c9db45"}
        disableInteraction={true}
        className="walkthrough-wrapper"
        nextButton={<Button className="walk-nav-button">Next</Button>}
        prevButton={<Button disabled={disableBack} className="walk-nav-button">Back</Button>}
        lastStepNextButton={<Button className="walk-nav-button" onClick={updateTutorialFlag}>Done</Button>}
        rounded={6}
        getCurrentStep={curr => setDisableBack(curr === 0 ? true : false)}
        disableFocusLock={true}
        onRequestClose={() => closeWalkthrough()} />}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
