import * as types from './constants';

export function markAsComplete(notificationId, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.post(`/notification/mark-as-complete/${notificationId}?type=${type}`)
    }
}

export function approveDocumentAccessRequest(notificationId, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.post(`/notification/approve/${notificationId}?type=${type}`)
    }
}

export function declineDocumentAccessRequest(notificationId, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.post(`/notification/reject/${notificationId}?type=${type}`)
    }
}

export function flagSuggestion(notificationId, payload, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.post(`/notification/flag-content/${notificationId}?type=${type}`, payload)
    }
}

export function approveSuggestion(notificationId, payload, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.post(`/notification/approve-content/${notificationId}?type=${type}`, payload)
    }
}

export function rejectSuggestion(notificationId, reason, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.post(`/notification/reject-content/${notificationId}?type=${type}`, {reason})
    }
}

export function deleteNotification(notificationId, type) {
    return {
        types: [
            types.NOTIFICATION_ACTION,
            types.NOTIFICATION_ACTION_SUCCESS,
            types.NOTIFICATION_ACTION_FAILURE,
            "NO_LOADER",
        ],
        promise: client => client.delete(`/notification/${notificationId}?type=${type}`)
    }
}





