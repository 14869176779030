import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PersonIcon from '@material-ui/icons/Person';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';

import { Util } from '../../utils/util';
import { profileImagePath } from "../../utils/utilServices";
import * as actions from "../../modules/Signin/sessionActions";
import * as modalActions from "../Modal/actions";

const mapStateToProps = state => ({
  showModal: state.modal.showProfileModal,
  user: state.session.user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});

function ProfileDialog(props) {
  const { user } = props;

  const [userData, setData] = React.useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    profileImage: null,
    email: user?.email
  });

  const handleChange = (event) => {
    setData({ ...userData, [event.target.id]: event.target.value });
  }

  const handleClose = () => {
    props.dispatch(modalActions.toggleProfileModal());
  }

  const handleFileChange = (e) => {
    let file = e.target.files[0];

    setData({ ...userData, profileImage: file })

    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('output');
      output.src = reader.result;
    }
    reader.readAsDataURL(file);
  }

  const saveUpdatedData = () => {
    // upload profile image
    if (userData.profileImage) {
      let imageFormData = new FormData();
      imageFormData.append('avatarfile', userData.profileImage);

      props.dispatch(actions.updateProfileImage(imageFormData));
    }

    // save other data
    let data = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: user.emailVerified ? undefined : userData.email
    }

    props.dispatch(actions.updateUserData(data))
      .then(res => {
        if (res.data.success) {
          // Util.setLoggedInUserDetails(res.data.payload)
          handleClose();
        } else {
          setData({
            firstName: user.firstName,
            lastName: user.lastName
          })
        }
      });
  }

  return (
    <div>
      <Dialog
        open={props.showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="profle-modal"
      >
        <DialogContent>
          <div className="upload-image-wrapper">
            <PersonIcon className="no-img-dummy" />
            <img id="output" alt="" src={profileImagePath(user._id) + '?' + new Date().getTime()} onError={e => e.target.hidden = true} onLoad={e => e.target.hidden = false} />

            <IconButton className="upload-img" onClick={() => document.getElementById("profile-image-picker").click()}>
              <AddAPhotoIcon />
            </IconButton>
            <input type="file" id="profile-image-picker" name="profileImgPicker"
              onChange={handleFileChange}
              style={{ opacity: 0, width: 0, height: 0 }} />
          </div>

          <div className="profile-inputs-wrapper">
            <TextField id="firstName" label="First name" variant="outlined" onChange={handleChange} value={userData.firstName} className="profile-input" />
            <TextField id="lastName" label="Last name" variant="outlined" onChange={handleChange} value={userData.lastName} className="profile-input" />
            <TextField id="email" label="Email" variant="outlined" value={userData.email} disabled={user?.emailVerified} className="profile-input" />
          </div>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>

          <Button color="primary" variant="contained" autoFocus onClick={() => saveUpdatedData()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileDialog);