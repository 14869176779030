import { combineReducers } from "redux";
import simpleReducer from "./simpleReducer";
import home from "../modules/Home/reducer";
import documents from "../modules/Documents/reducer";
import modal from "../components/Modal/reducer";
import session from "../modules/Signin/sessionReducer";
import mediaLibrary from "../modules/MediaLibrary/reducer";
import workspace from "../modules/Workspace/reducer";
import notify from "../components/NotificationsDrawer/reducer";

const appReducer = combineReducers({
  simpleReducer,
  home,
  documents,
  modal,
  session,
  mediaLibrary,
  workspace,
  notify
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer;