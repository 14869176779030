// signin actions
export const LOAD_SIGNIN = 'my-project/session/LOAD_SIGNIN';
export const LOAD_SIGNIN_SUCCESS =
  'my-project/session/LOAD_SIGNIN_SUCCESS';
export const LOAD_SIGNIN_FAILURE =
  'my-project/session/LOAD_SIGNIN_FAILURE';

// signup actions
export const LOAD_SIGNUP = 'my-project/session/LOAD_SIGNUP';
export const LOAD_SIGNUP_SUCCESS =
  'my-project/session/LOAD_SIGNUP_SUCCESS';
export const LOAD_SIGNUP_FAILURE =
  'my-project/session/LOAD_SIGNUP_FAILURE';

// load self
export const LOAD_SELF = 'my-project/session/LOAD_SELF';
export const LOAD_SELF_SUCCESS =
  'my-project/session/LOAD_SELF_SUCCESS';
export const LOAD_SELF_FAILURE =
  'my-project/session/LOAD_SELF_SUCCESS';

// update user data

export const LOAD_UPDATE_USER_DATA = 'my-project/session/LOAD_UPDATE_USER_DATA';
export const LOAD_UPDATE_USER_DATA_SUCCESS =
  'my-project/session/LOAD_UPDATE_USER_DATA_SUCCESS';
export const LOAD_UPDATE_USER_DATA_FAILURE =
  'my-project/session/LOAD_UPDATE_USER_DATA_FAILURE';

// update profile image

export const LOAD_UPDATE_PROFILE_IMAGE = 'my-project/session/LOAD_UPDATE_PROFILE_IMAGE';
export const LOAD_UPDATE_PROFILE_IMAGE_SUCCESS =
  'my-project/session/LOAD_UPDATE_PROFILE_IMAGE_FAILURE';
export const LOAD_UPDATE_PROFILE_IMAGE_FAILURE =
  'my-project/session/LOAD_UPDATE_PROFILE_IMAGE_FAILURE';

  // verify email

export const LOAD_VERIFY_EMAIL = 'my-project/session/LOAD_VERIFY_EMAIL';
export const LOAD_VERIFY_EMAIL_SUCCESS =
  'my-project/session/LOAD_VERIFY_EMAIL_SUCCESS';
export const LOAD_VERIFY_EMAIL_FAILURE =
  'my-project/session/LOAD_VERIFY_EMAIL_FAILURE';

// send password reset email

export const SEND_PASSWORD_RESET_EMAIL = 'my-project/session/SEND_PASSWORD_RESET_EMAIL';
export const SEND_PASSWORD_RESET_EMAIL_SUCCESS =
  'my-project/session/SEND_PASSWORD_RESET_EMAIL_SUCCESS';
export const SEND_PASSWORD_RESET_EMAIL_FAILURE =
  'my-project/session/SEND_PASSWORD_RESET_EMAIL_FAILURE';

// add new user

export const ADD_NEW_USER = 'my-project/session/ADD_NEW_USER';
export const ADD_NEW_USER_SUCCESS =
  'my-project/session/ADD_NEW_USER_SUCCESS';
export const ADD_NEW_USER_FAILURE =
  'my-project/session/ADD_NEW_USER_FAILURE';

// update account user

export const UPDATE_ACCOUNT_USER = 'my-project/session/UPDATE_ACCOUNT_USER';
export const UPDATE_ACCOUNT_USER_SUCCESS =
  'my-project/session/UPDATE_ACCOUNT_USER_SUCCESS';
export const UPDATE_ACCOUNT_USER_FAILURE =
  'my-project/session/UPDATE_ACCOUNT_USER_FAILURE';

// fetch users list

export const FETCH_USERS_LIST = 'my-project/session/FETCH_USERS_LIST';
export const FETCH_USERS_LIST_SUCCESS =
  'my-project/session/FETCH_USERS_LIST_SUCCESS';
export const FETCH_USERS_LIST_FAILURE =
  'my-project/session/FETCH_USERS_LIST_FAILURE';

// fetch users list

export const DELETE_ACCOUNT_USER = 'my-project/session/DELETE_ACCOUNT_USER';
export const DELETE_ACCOUNT_USER_SUCCESS =
  'my-project/session/DELETE_ACCOUNT_USER_SUCCESS';
export const DELETE_ACCOUNT_USER_FAILURE =
  'my-project/session/DELETE_ACCOUNT_USER_FAILURE';


//-------
export const LOAD_USER_DATA = 'my-project/session/LOAD_USER_DATA';
export const LOAD_LOGOUT =
  'my-project/session/LOAD_LOGOUT';
