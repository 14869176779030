export const LOAD_MEDIA = "documents/LOAD_MEDIA";
export const LOAD_MEDIA_SUCCESS = "documents/LOAD_MEDIA_SUCCESS";
export const LOAD_MEDIA_FAILURE = "documents/LOAD_MEDIA_FAILURE";

export const DELETE_IMAGE = "documents/DELETE_IMAGE";
export const DELETE_IMAGE_SUCCESS = "documents/DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "documents/DELETE_IMAGE_FAILURE";

export const UPLOAD_IMAGE = "documents/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "documents/UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "documents/UPLOAD_IMAGE_FAILURE";