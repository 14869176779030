import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import LinkIcon from '@material-ui/icons/Link';

import * as docUserActions from "../../modules/CreateDocument/actions";
import { copyText, profileImagePath } from "../../utils/utilServices";
import { defaultPatterns } from "../../utils/constants";

import * as modalActions from "./actions";

const mapStateToProps = state => ({
  showModal: state.modal.shareModal.showShareDocumentModal,
  documentId: state.modal.shareModal.documentId
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...modalActions }),
  dispatch
});

const emailPattern = new RegExp(defaultPatterns.email);

function ShareDocumentDialog(props) {
  const [message, setMessage] = React.useState([]); //search result 
  const [users, setUsers] = React.useState([]); //search result 
  const [sharedUsers, setSharedUsers] = React.useState([]); // users selected for sharing

  useEffect(() => {
    if (props.showModal) {
      props.dispatch(docUserActions.getShareDetails(props.documentId))
      .then(res => {
        if(res.data.success) {
          setSharedUsers(res.data.payload.users.concat(res.data.payload.emails))
        }
      })
    }
  }, [props.showModal])

  const handleClose = () => {
    props.dispatch(modalActions.toggleShareDocumentModal());
    setMessage("")
    setUsers([])
    setSharedUsers([])
  }

  const onUsersSearchInputChange = (e, val, reason) => {
    if (reason === "input") {
      props.dispatch(docUserActions.searchUser(val))
        .then(res => {
          if (res.data.success) {
            setUsers(res.data.payload.docs)
          }
        })
    }
  }

  const addUser = (val, e) => {
    let newValues = [];

    val.slice().map((item, index) => {
      if ((typeof (item) === "string" && emailPattern.test(item)) || typeof (item) === "object") {
        newValues.push(item);
      }
    })

    // let last_item = newValues[newValues.length - 1];
    // if (newValues.findIndex(e => typeof(e) === "object" && e._id === last_item._id) !== -1) {
    //   newValues.splice(newValues.length-1, 1)
    // }

    setSharedUsers(newValues)
  }

  const shareDocument = () => {
    let users = [];
    let emails = [];

    sharedUsers.map((item) => {
      if (typeof(item) === "string") {
        emails.push(item);
      } else {
        users.push(item._id);
      }
    })

    let data = {
      message,
      users,
      emails
    }

    props.dispatch(docUserActions.shareDocument(data, props.documentId))
    .then(res => {
      if (res.data.success) {
        handleClose();
        props.dispatch(modalActions.toggleSnackbar("Document shared"));
      }
    })
  }

  return (
    <div>
      <Dialog
        open={props.showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="share-document-modal"
      >
        <DialogContent>
          <h6 onClick={() => console.log(sharedUsers)}>Share document</h6>

          {/* <div className="copy-link-box" onClick={() => copyText("")}>
            <LinkIcon />
            <span>Copy document link & Passcode</span>
          </div> */}

          <div className="assignee-autocomplete-wrap">
            <Autocomplete
              value={sharedUsers}
              multiple
              filterOptions={(options, state) => options}
              id="tags-outlined"
              freeSolo={true}
              autoSelect
              options={users}
              getOptionLabel={(option) => option ? (option.firstName ? `${option.firstName} ${option.lastName}` : option) : ""}
              onInputChange={(e, val, reason) => onUsersSearchInputChange(e, val, reason)}
              onChange={(e, val) => val ? addUser(val, e) : {}}
              filterSelectedOptions
              renderOption={(option) => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar alt={`${option.firstName} ${option.lastName}`} src={profileImagePath(option.profile)} />
                  </ListItemAvatar>
                  <ListItemText primary={`${option.firstName} ${option.lastName}`} secondary={option.email} />
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Add people"
                  placeholder="Add people"
                />
              )}
            />
          </div>

          <TextField id="outlined-basic"
            className="add-message-textarea"
            value={message}
            onChange={e => setMessage(e.target.value)}
            label="Add a message"
            variant="outlined" multiline rows={4} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>

          <Button color="primary" variant="contained"
            disabled={!sharedUsers.length}
            autoFocus onClick={shareDocument}>
            Share
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareDocumentDialog);