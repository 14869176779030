import React from 'react';
import TextField from '@material-ui/core/TextField';
import { defaultPatterns, errorMessages } from "../../utils/constants";

export default function FlipInput(props) {
  const {
    label,
    id,
    required,
    type,
    readOnly,
    name,
    value,
    disabled
  } = props;

  const [inputData, setData] = React.useState(
    {
      value: "",
      valid: true,
      errorMessage: ""
    }
  )

  const validateInput = (input) => {
    if (!input) {
      input = document.getElementById(id);
    }

    if (input.value.length === 0 && required) {
      setData(prevState => ({
        ...prevState,
        errorMessage: errorMessages.required,
        valid: false
      }));
      props.setValid(false);
    } else if (!input.validity.valid) {
      setData(prevState => ({
        ...prevState,
        errorMessage: errorMessages.invalid + input.name,
        valid: false
      }));
      props.setValid(false);
    } else {
      setData(prevState => ({
        ...prevState,
        errorMessage: "",
        valid: true
      }));
      props.setValid(true);
    }
  }

  const handleChange = (e) => {
    setData(prevState => ({
      ...prevState,
      value: e.target.value
    }));
    props.handleChange(e);
  }

  const onFocus = (e) => {
    setData(prevState => ({
      errorMessage: "",
      valid: true
    }));
  }

  return (
    <TextField variant="outlined"
      fullWidth
      id={id}
      name={name}
      label={label}
      required={required}
      type={type}
      disabled={disabled}
      inputProps={{
        pattern: defaultPatterns[type],
        readOnly: readOnly
      }}
      value={value}
      onChange={handleChange}
      onBlur={event => validateInput(event.target)}
      onFocus={onFocus}
      error={!inputData.valid}
      helperText={inputData.errorMessage}
    />
  )
}

FlipInput.defaultProps = {
  name: "",
  type: "text",
  required: false,
  readOnly: false,
  disabled: false
}

