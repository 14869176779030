import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import * as modalActions from "../Modal/actions";

const mapStateToProps = state => ({
    snackBar: state.modal.snackBar,
  });
  
  const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ ...modalActions }),
    dispatch
  });

function FlipSnackbar(props) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.dispatch(modalActions.toggleSnackbar());
  };

  const buttonAction = () => {
    props.snackBar.button.action();

    handleClose();
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={props.snackBar.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={props.snackBar.message}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={buttonAction}>
              {props.snackBar.button.name}
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(FlipSnackbar);