import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ClearIcon from '@material-ui/icons/Clear';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';

import * as modalActions from "./actions";
import * as docActions from "../../modules/Documents/actions";

const mapStateToProps = state => ({
  showModal: state.modal.showDocumentCreateModal,
  currentPath: state.documents.currentPath,
  parentFolderId: state.documents.currentFolder?.id
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...modalActions }),
  dispatch
});

function ChooseDocumentTemplateDialog(props) {
  const history = useHistory();

  const [templates, setTemplates] = React.useState([]);

  useEffect(() => {
    if (props.showModal) {
      props.dispatch(docActions.getAllTemplates())
        .then(res => {
          if (res.data.success) {
            setTemplates(res.data.payload)
          }
        })
    }

  }, [props.showModal])

  const onTemplateClick = (id) => {
    props.dispatch(docActions.createDocument(
      {
        title: "Untitled",
        folderId: props.parentFolderId
      },
      isTemplate(), id
    )).then(res => {
      if (res.data.success) {
        let id = res.data.payload._id;
        window.open(`/create-document/${id}`, "_blank");
      }
    })

    props.dispatch(modalActions.toggleCreateDocumentModal());
  }

  const isTemplate = () => {
    if (props.currentPath.length > 1 && props.currentPath[1].name === "My Templates") {
      return true;
    }

    return false;
  }

  return (
    <div>
      <Dialog
        open={props.showModal}
        onClose={() => props.dispatch(modalActions.toggleCreateDocumentModal())}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="choose-template-modal"
      >
        <DialogContent>
          <div className="header">
            <h6>Create new policy</h6>
            <ClearIcon onClick={() => props.dispatch(modalActions.toggleCreateDocumentModal())} />
          </div>

          <div className="grid-container">
            <div className="template-box" onClick={() => onTemplateClick()}>
              <div className="template">
                <InsertDriveFileOutlinedIcon />
              </div>
              <span>Blank policy</span>
            </div>

            {/* Map the templates here */}
            {
              templates.map((item, index) => {
                return (
                  <div className="template-box" key={index} onClick={() => onTemplateClick(item._id)}>
                    <div className="template" style={{
                      backgroundImage: `url(${item.background})`
                    }}>
                      <div className="dummy" >

                      </div>
                    </div>
                    <span>{item.title}</span>
                  </div>
                )
              })
            }
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseDocumentTemplateDialog);