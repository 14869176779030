import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import FlipInput from "../Input";
import Tabs, { TabPanel } from "../Tabs";

import * as actions from "./actions";
import { isEmptyObj, checkIfEqual } from "../../utils/utilServices";
import { errorMessages } from "../../utils/constants";
import * as sessionActions from "../../modules/Signin/sessionActions";

const mapStateToProps = state => ({
  showAddNewUserModal: state.modal.showAddNewUserModal,
  editUser: state.modal.editUser,
  page: state.session.pagination.page
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});

const labels = {
  publishDocuments: "Publish documents",
  manageDocumentSettings: "Manage document settings",
  deleteDocuments: "Delete documents",
  approveNewUserAccess: "Approve new user access",
  manageUsers: "Manage users",
  approveSuggestedContent: "Approve suggestions"
}

const initalChecks = [
  {
    checked: false,
    key: "publishDocuments"
  },
  {
    checked: false,
    key: "manageDocumentSettings"
  },
  {
    checked: false,
    key: "deleteDocuments"
  },
  {
    checked: false,
    key: "approveNewUserAccess"
  },
  {
    checked: false,
    key: "manageUsers"
  },
  {
    checked: false,
    key: "approveSuggestedContent"
  },
];

function AddNewUserDialog(props) {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(true);
  const [apiError, setApiError] = React.useState("");
  const [activeTabIndex, setActiveTab] = React.useState(0);
  const [selectedRole, setRole] = React.useState("");
  const [permissionChecks, setPermissionChecks] = React.useState(initalChecks);
  const [noOfCheckedItems, setCheckedNumber] = React.useState(0);

  useEffect(() => {
    if (props.showAddNewUserModal && props.editUser) {
      let { editUser } = props;

      if (editUser.settings) {
        let editUserPermissionChecks = [];
        Object.keys(editUser.settings).map((item) =>
          editUserPermissionChecks.push(
            { key: item, checked: editUser.settings[item] }
          ));
        setPermissionChecks(editUserPermissionChecks);
      }

      setFirstName(editUser.firstName);
      setLastName(editUser.lastName);
      setEmail(editUser.email);
      editUser.role && setRole(editUser.role);
    }
  }, [props.showAddNewUserModal])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleClose = () => {
    props.dispatch(actions.toggleUserModal());
    setFirstName("");
    setLastName("");
    setEmail("");
    setEmailValid(true);
    setApiError("");
    setActiveTab(0);
    setRole("");
    setPermissionChecks(initalChecks);
  };

  const handleRoleChange = (event) => {
    if (event.target.value === "reader") {
      setPermissionChecks(initalChecks);
    }
    setRole(event.target.value);
  }

  const handlePermissionsChange = (event, index) => {
    let permissions = permissionChecks.map((e, i) => i === index || index === 'all' ? { ...e, checked: event.target.checked } : e)
    setPermissionChecks(permissions)
    
    findNoOfCheckedItems(permissions);
  }

  const checkIfAtleastOnePermissionChecked = () => {
    let atleastOnePermissionChecked = false;

    permissionChecks.map(item => {
      if (item.checked) {
        atleastOnePermissionChecked = true;
      }
    });

    return atleastOnePermissionChecked;
  }

  const addOrSaveUser = () => {
    // only allow save if author has atleast one permission

    if (selectedRole === "author" && !checkIfAtleastOnePermissionChecked()) {
      setActiveTab(0);
      setApiError("Author should have atleast one permission.");

      setTimeout(() => {
        setApiError("");
      }, 10000);
      return;
    }


    if (props.editUser) {
      saveUser();
      return;
    }

    if (emailValid && selectedRole) {
      let settings = {};

      permissionChecks.map(item => settings[item.key] = item.checked)

      let data = {
        email,
        role: selectedRole,
        firstName,
        lastName,
        settings
      }

      props.dispatch(sessionActions.addNewUser(data)).then(
        res => {
          if (res.data.success) {
            props.dispatch(actions.toggleSnackbar("New user added successfully!"));
            props.dispatch(sessionActions.fetchUsersList(1, false));
            handleClose();
          } else {

            let errorMsg = res.data.error.errorMessage;
            if (errorMsg === "ALREADY_ADDED_USER") {
              setApiError(errorMessages[errorMsg]);
            } else {
              setApiError("Could not add new user");
            }

            setTimeout(() => {
              setApiError("");
            }, 10000)
          }
        }
      );
    }
  }

  const findNoOfCheckedItems = (permissions) => {
    let checked = 0;

    permissions.map(item => {
      if (item.checked) {
        checked = checked + 1;
      }
    })
    
    setCheckedNumber(checked);
  }

  const saveUser = () => {
    let settings = {};

    permissionChecks.map(item => settings[item.key] = item.checked)

    let { editUser } = props;

    let data = {
      role: editUser.role !== selectedRole ? selectedRole : undefined,
      firstName: editUser.firstName !== firstName ? firstName : undefined,
      lastName: editUser.lastName !== lastName ? lastName : undefined,
      settings: !checkIfEqual(settings, editUser.settings) ? settings : undefined
    }

    if (!isEmptyObj(data)) {
      props.dispatch(sessionActions.editAccountUser(data, props.editUser._id))
        .then(res => {
          if (res.data.success) {
            props.dispatch(sessionActions.fetchUsersList(props.page, false));
            handleClose();
          } else {
            setApiError("Something went wrong. Please try again.");
            setTimeout(() => {
              setApiError("");
            }, 10000)
          }
        });
    }
  }

  return (
    <div>
      <Dialog
        open={props.showAddNewUserModal}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="add-new-user-dialog"
      >
        <DialogTitle id="alert-dialog-title" className="title">
          {props.editUser ? "Edit user" : "Add new user"}
        </DialogTitle>

        <DialogContent className="content">
          <Tabs id="add-user-modal-tab"
            tabNames={['1. Details', '2. Settings']}
            activeTab={activeTabIndex}
            handleTabChange={handleTabChange} />

          {/* Details tab */}
          <TabPanel value={activeTabIndex} index={0}>
            {apiError &&
              <FormHelperText className="api-error">
                {apiError}
              </FormHelperText>}
            <form className={""} noValidate>
              <Grid container spacing={2}>
                {/* First name */}
                <Grid item xs={12} sm={6}>
                  <TextField id="firstName" value={firstName} onChange={e => setFirstName(e.target.value)} label="First name" variant="outlined" color="primary" />
                </Grid>

                {/* Last name */}
                <Grid item xs={12} sm={6}>
                  <TextField id="lastName" value={lastName} onChange={e => setLastName(e.target.value)} label="Last name" variant="outlined" />
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <FlipInput id="newUserEmail" name="email"
                    label="Email" required={true}
                    value={email}
                    disabled={props.editUser ? true : false}
                    type="email"
                    setValid={valid => setEmailValid(valid)}
                    handleChange={e => setEmail(e.target.value)}
                  />
                </Grid>

                {/* Role */}
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={""}>
                    <InputLabel id="demo-simple-select-outlined-label" required>Role</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={selectedRole}
                      onChange={handleRoleChange}
                      label="Role"
                    >
                      <MenuItem value={"reader"}>Reader</MenuItem>
                      <MenuItem value={"author"}>Author</MenuItem>
                    </Select>
                    <FormHelperText>
                      Mandatory
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </TabPanel>

          {/* Settings tab */}
          <TabPanel value={activeTabIndex} index={1}>
            <FormControlLabel
              label="All permissions"
              control={
                <Checkbox
                  checked={noOfCheckedItems === 6}
                  indeterminate={noOfCheckedItems > 0 && noOfCheckedItems < 6}
                  onChange={(ev) => handlePermissionsChange(ev, 'all')}
                  color="primary"
                />
              }
            />
            <Grid container spacing={2} className="checkboxes-wrapper">
              {
                permissionChecks.map((item, index) => {
                  return (
                    <Grid item xs={12} sm={6} key={index} className="checkbox-container">
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={selectedRole === "reader"}
                            checked={item.checked}
                            onChange={(ev) => handlePermissionsChange(ev, index)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label={labels[item.key]}
                      />
                    </Grid>
                  );
                })
              }
            </Grid>
          </TabPanel>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose()} variant="outlined">
            Cancel
          </Button>
          <Button disabled={!emailValid || !selectedRole}
            onClick={() => addOrSaveUser()} color="primary" variant="contained" autoFocus>
            {props.editUser ? "Save" : "Add user"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewUserDialog)