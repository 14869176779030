export function isMobile() {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}

export function imagePath(assetName) {
  return `/images/${assetName}`;
}   

export function profileImagePath(id) {
  let baseUrl = "http://185.155.104.119/api";

  return `${baseUrl}/user/image/${id}`
}

export function parseQuery(searchQuery) {
  var searchParams = new URLSearchParams(searchQuery);

  let res = {};

  for(var pair of searchParams.entries()) {
    res[pair[0]] = pair[1]
 }

 return res;
}

export function copyText(textToCopy) {
  if (!navigator.clipboard) {
    console.error('Copy not supported!');
    return;
  }

  return navigator.clipboard.writeText(textToCopy);
}

export function formatDate(date, withTime) {
  if (!date) {
    return "";
  }

  let options = { year: 'numeric', month: 'long', day: 'numeric' };
  let locale = "en-GB";

  if (withTime) {
    locale = "en-US"
    options = {month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true}
  }
  
  date = new Date(date);
  
  return date.toLocaleString(locale, options);
}

export function splitBy_(string) {
  return string.split("_").join(" ");
}

export function convertToNiceDate(date) {
  let subjectDate = new Date(date);

  let today = new Date();

  let diff = ((today.getTime() - subjectDate.getTime()) / 1000)
  let daydiff = Math.floor(diff / 86400);

  if (isNaN(daydiff) || daydiff < 0)
    return '';
  
    return daydiff === 0 && (
      (diff < 60 && "Just now") ||
      (diff < 120 && "1 minute ago") ||
      (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
      (diff < 7200 && "1 hour ago") ||
      (diff < 86400 && Math.floor(diff / 3600) + " hours ago")) ||
      (daydiff === 1 && "Yesterday") ||
      (daydiff < 7 && daydiff + " days ago") ||
      (daydiff < 31 && Math.ceil(daydiff / 7) + " week(s) ago") ||
      (daydiff >= 31 && formatDate(subjectDate, true));
}

export function checkIfEqual(obj1, obj2) {
  return Object.entries(obj1).toString() === Object.entries(obj2).toString() 
}

export function isEmptyObj(obj) {
  let empty = true;
  for (var key in obj) {
      if (obj[key] !== undefined) {
        empty = false; break;
      }
      
  }
  return empty;
}