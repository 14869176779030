import * as types from "../Documents/constants";

export function searchUser(searchTerm) {
    return {
        types: ["NO_LOADER"],
        promise: client => client.get(`/user/users?search=${searchTerm}`)
    }
}

export function addAssignee(userId, sectionId, documentId) {
    let data = {
        to: userId,
        documentId
    }

    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.post(`section/${sectionId}/assign`, data)
    }
}

export function removeAssignee(sectionId, assigneeId, documentId) {
    let url = `/section/${sectionId}/assignments/${assigneeId}?documentId=${documentId}`;
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.delete(url)
    }
}

export function sendReminderToAssignee(sectionId, assigneeId, documentId) {
    let url = `/section/${sectionId}/assignments/${assigneeId}/remind?documentId=${documentId}`;
    return {
        types: [
            // types.LOAD_DOCUMENT_DETAILS,
            // types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            // types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.post(url)
    }
}

export function deleteSuggestion(sectionId, suggestionId, reason, type) { //type for approve or reject
    let url = `/suggestion/${sectionId}/${suggestionId}/delete?type=${type}`;
    return {
        types: [
            types.DELETE_SUGGESTIONS,
            types.DELETE_SUGGESTIONS_SUCCESS,
            types.DELETE_SUGGESTIONS_FAILURE,
            "NO_LOADER"
        ],
        section: sectionId,
        promise: client => client.post(url, {reason})
    }
}

export function addComment(comment, documentId, sectionId) {
    let data = {
        comment,
        section: sectionId
    }

    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.post(`/document/${documentId}/comment`, data)
    }
}

export function deleteComment(commentId, documentId, sectionId) {
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.delete(`/document/${documentId}/section/${sectionId}/comment/${commentId}`)
    }
}

export function editComment(commentId, comment, documentId) {
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.put(`/document/${documentId}/comment/${commentId}`, {comment})
    }
}

export function addNote(content, documentId, sectionId) {
    let data = {
        content,
        section: sectionId
    }

    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.post(`/document/${documentId}/note`, data)
    }
}

export function deleteNote(noteId) {
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.delete(`/document/note/${noteId}`)
    }
}

export function editNote(noteId, content) {
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.put(`/document/note/${noteId}`, {content})
    }
}

export function like(documentId, likeBool, sectionId) {
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.post(`/document/${documentId}/${likeBool ? "like" : "unlike"}`, {section: sectionId})
    }
}

export function acknowledge(documentId, sendCopy) {
    return {
        types: [
            types.LOAD_DOCUMENT_DETAILS,
            types.LOAD_DOCUMENT_DETAILS_SUCCESS,
            types.LOAD_DOCUMENT_DETAILS_FAILURE,
            "NO_LOADER"
        ],
        promise: client => client.post(`/document/${documentId}/mark-acknowledge${sendCopy ? "?send-copy=true" : ""}`)
    }
}

export function requestDocumentAccess(documentId) {
    return {
        types: [""],
        promise: client => client.post(`/document/published/${documentId}/access`)
    }
}

export function requestEditDocumentAccess(documentId) {
    return {
        types: [""],
        promise: client => client.post(`/document/${documentId}/edit-access`)
    }
}

export function shareDocument(data, documentId) {
    return {
        types: [""],
        promise: client => client.post(`/document/${documentId}/share`, data)
    }
}

export function getShareDetails(documentId) {
    return {
        types: ["NO_LOADER"],
        promise: client => client.get(`/document/${documentId}/share`)
    }
}

export function submitImageSuggestion(file, documentId, sectionId) {
    return {
        types: [""],
        promise: client => client.post(`/suggestion/${documentId}/${sectionId}/image`, file)
    }
}

export function submitSuggestion(data, documentId, sectionId) {
    return {
        types: [""],
        promise: client => client.post(`/suggestion/${documentId}/${sectionId}`, data)
    }
}
