import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import "./dropdown.scss";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus(props) {
  const handleMenuClick = (item) => {
    props.handleClose();
    props.handleMenuClick(item);
  }

  return (
    <div>
      <StyledMenu
        id={props.id}
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.handleClose}
        className="dropdown-wrapper"
      >
        {props.data.map((item, index) => {
          return (
            <StyledMenuItem onClick={() => handleMenuClick(item)} key={index}
              disabled={props.disableCondition(item.optionName)}
              className={props.dividerIndices.includes(index) ? "add-border" : ""}
            >
              {item.icon && <ListItemIcon>
                {item.icon}
              </ListItemIcon>}
              <ListItemText primary={item.text} />
            </StyledMenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
}

CustomizedMenus.propTypes = {
  id: PropTypes.string,
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  data: PropTypes.array,
  dividerIndices: PropTypes.array,
  handleMenuClick: PropTypes.func,
};

CustomizedMenus.defaultProps = {
  dividerIndices: [],
  handleMenuClick: () => { },
  disableCondition: () => { return false }
}

