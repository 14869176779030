import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pusher from 'pusher-js';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Badge from '@material-ui/core/Badge';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonIcon from '@material-ui/icons/Person';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LinearProgress from '@material-ui/core/LinearProgress';
import MessageIcon from '@material-ui/icons/Message';
import Box from '@material-ui/core/Box';
import RightDrawerCard from "./notifyCard";

import "./notificationsDrawer.scss";
import * as actions from "../../modules/Signin/sessionActions";
import * as notifActions from "./actions";
import * as modalActions from "../Modal/actions";
import { checkDocumentPublishStatus } from "../../modules/Documents/actions";

const mapStateToProps = state => ({
  isLoading: state.notify.isLoading,
  user: state.session.user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }),
  dispatch
});

const useStyles = makeStyles(theme => ({
  list: {
    [theme.breakpoints.down('lg')]: {
      width: 438,
    },
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
    width: 500,
  },
}));

let modes = {
  0: "access",
  1: "suggestion",
  2: "assignment",
  3: "compliance",
  4: "comment",
}

function TemporaryDrawer(props) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [animate, setanimate] = React.useState(false);
  const [isNotifications, setIsNotifications] = React.useState(false);
  const [notifications, setNotifications] = React.useState({
    access: [],
    suggestion: [],
    assignment: [],
    compliance: [],
    comment: []
  })

  useEffect(() => {
    const pusher = new Pusher('62498ed231425330c592', {
      cluster: 'eu',
      // encrypted: true
    });
    const channel = pusher.subscribe(props.user._id);

    channel.bind('notification', data => {
      fetchNotifications();
      setanimate(true);

      setTimeout(() => {
        setanimate(false);
      }, 2000);
    });

    fetchNotifications();
  }, [])

  const fetchNotifications = () => {
    props.dispatch(actions.fetchNotifications())
    .then(res => {
      if (res.data.success) {
        setNotifications({
          access: [],
          suggestion: [],
          assignment: [],
          compliance: [],
          comment: []
        })

        setIsNotifications(res.data.payload.length > 0)

        res.data.payload.map((item, index) => {
          setNotifications(prevState => ({
            ...prevState,
            [item.type]: [...prevState[item.type], item]
          }))
        })
      }
    })
  }

  const handleChange = (event, tab) => {
    setActiveTab(tab);
  };

  const toggleDrawer = () => {
    setDrawerOpen(false);
  };

  // card actions

  const markAsComplete = (notifId, type) => {
    props.dispatch(notifActions.markAsComplete(notifId, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      });
  }

  const viewDocument = (sectionId, documentId, notifType) => {
    let url = "";

    if (notifType === "comment") {
      props.dispatch(checkDocumentPublishStatus(documentId))
        .then(res => {
          if (res.data.success) {
            let isPublished = res.data.payload.isPublished;

            if (isPublished) {
              url = `/document/${res.data.payload.published_document}?action=view_comment${sectionId ? '#' + sectionId : ""}`;
            } else {
              url = `/create-document/${documentId}?action=view_comment${sectionId ? '#' + sectionId : ""}`;
            }
            window.open(url, "_blank");
          }
        });
    } else if (notifType === "access" || notifType === "compliance") {
      let url = `/document/${documentId}`;
      window.open(url, "_blank");
    } else {
      url = `/create-document/${documentId}${sectionId ? '#' + sectionId : ""}`;
      window.open(url, "_blank");
    }
  }

  const approveDocumentAccess = (notifId, type) => {
    props.dispatch(notifActions.approveDocumentAccessRequest(notifId, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      })
  }

  const showUserModal = (data) => {
    props.dispatch(modalActions.toggleUserModal({
      email: data.createdBy.email,
      firstName: data.createdBy.firstName,
      lastName: data.createdBy.lastName,
      id: data.createdBy._id
    }))
  }

  const approveDocumentEditAccess = (data) => {
    if (!data.content.accountPermission) {
      props.dispatch(modalActions.showModal({
        message: <p>Please add this user to your account to proceed.</p>,
        buttons: [
          {
            text: 'Cancel',
            action: () => {}
          },
          {
            text: 'OK',
            action: () => showUserModal(data)
          }
        ]
      }));
    } else if (data.content.role === "reader") {
      props.dispatch(modalActions.showModal({
        message: <p>This user does not have author access. Please change user's role to author from manage users.</p>,
        buttons: [
          {
            text: 'OK',
            action: () => {}
          }
        ]
      }));
    } else if (!data.content.documentLevelPermission && data.content.role === "author") {
      approveDocumentAccess(data._id, data.type)
    }
  }

  const rejectDocumentAccess = (notifId, type) => {
    props.dispatch(notifActions.declineDocumentAccessRequest(notifId, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      })
  }

  const flagSuggestion = (notifId, type, payload) => {
    props.dispatch(notifActions.flagSuggestion(notifId, payload, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      })
  }

  const rejectSuggestion = (notifId, type, reason) => {
    props.dispatch(notifActions.rejectSuggestion(notifId, reason, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      })
  }

  const approveSuggestion = (notifId, type, payload) => {
    props.dispatch(notifActions.approveSuggestion(notifId, payload, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      })
  }

  const deleteNotification = (notifId, type) => {
    props.dispatch(notifActions.deleteNotification(notifId, type))
      .then(res => {
        if (res.data.success) {
          setNotificationByType(type, res.data.payload)
        }
      })
  }

  const setNotificationByType = (type, data) => {
    setNotifications(prevState => ({
      ...prevState,
      [type]: data
    }));

    checkIfNotificationsEmpty();
  }

  const checkIfNotificationsEmpty = () => {
    let empty = true;

    Object.keys(modes).map(item => {
      if (notifications[modes[item]].length > 0) {
        empty = false;
      }
    });

    setIsNotifications(!empty);
  }

  const handleCardAction = (buttonName, data, payload) => {
    switch (buttonName) {
      case "mark_as_complete":
        markAsComplete(data._id, data.type);
        break;
      case "view":
        viewDocument(data.content.sectionId, data.content.documentId, data.type);
        break;
      case "open_document":
        viewDocument(data.content.sectionId, data.content.documentId, data.type);
        break;
      case "approve":
        if (data.tag === "document_edit_access") {
          approveDocumentEditAccess(data);
        } else if (data.type === "suggestion") {
          approveSuggestion(data._id, data.type, payload);
        } else {
          approveDocumentAccess(data._id, data.type);
        }
        break;
      case "decline":
        if (data.type === "suggestion") {
          rejectSuggestion(data._id, data.type, payload.reason)
        } else if (data.type === "access") {
          rejectDocumentAccess(data._id, data.type);
        }
        break;
      case "flag":
        flagSuggestion(data._id, data.type, payload);
        break;
      case "discard":
        deleteNotification(data._id, data.type);
        break
      default: break;
    }
  }

  return (
    <div>
      <Badge variant="dot" invisible={!isNotifications} color="error" onClick={() => setDrawerOpen(true)} className="notification-trigger">
        <NotificationsNoneIcon className={animate ? "animate-bell" : ""} />
      </Badge>

      <Drawer anchor={'right'} open={drawerOpen} onClose={toggleDrawer}>
        <div
          className={classes.list}
          role="presentation"
        >
          <div className="right-drawer-header">
            <h6>Notifications</h6>
            <ClearIcon onClick={toggleDrawer} className="close-icon" />
          </div>

          <Divider />

          <Tabs
            value={activeTab}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
            className="right-drawer-tabs"
          >
            <Tab icon={
              <Badge color="secondary" variant="dot" invisible={notifications.access.length === 0}>
                <PersonIcon />
              </Badge>
            } label="access" />

            <Tab icon={
              <Badge color="secondary" variant="dot" invisible={notifications.suggestion.length === 0}>
                <EmojiObjectsIcon />
              </Badge>
            } label="suggestion" />

            <Tab icon={
              <Badge color="secondary" variant="dot" invisible={notifications.assignment.length === 0}>
                <AssignmentIndIcon />
              </Badge>
            } label="assignment" />

            <Tab icon={
              <Badge color="secondary" variant="dot" invisible={notifications.compliance.length === 0}>
                <VerifiedUserIcon />
              </Badge>
            } label="compliance" />

            <Tab icon={
              <Badge color="secondary" variant="dot" invisible={notifications.comment.length === 0}>
                <MessageIcon />
              </Badge>
            } label="comment" />
          </Tabs>

          {
            props.isLoading &&
            <LinearProgress color="primary" className="progress-bar" />
          }

          <Box p={3}>
            {
              notifications[modes[activeTab]].length > 0 ?
                notifications[modes[activeTab]].map((item, index) => {
                  return <RightDrawerCard
                    onClick={handleCardAction}
                    mode={item.tag ? item.tag : item.type}
                    data={item}
                    key={index} />
                })
                :
                <div className="no-notifications-container">
                  <p className="main">Nothing to see here.</p>
                  <p className="desc">You're all up to date.</p>
                </div>
            }
          </Box>

        </div>
      </Drawer>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemporaryDrawer);