import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {Util} from "./utils/util";
import Filler from "./components/Skeleton";

const Home = lazy(() => import("./modules/Home"));

const NotFound = lazy(() => import("./modules/NotFound"));

const Documents = lazy(() => import("./modules/Documents"));

const SignIn = lazy(() => import("./modules/Signin"));

const SignUp = lazy(() => import("./modules/Signup"));

const Workspace = lazy(() => import("./modules/Workspace"));

const CreateDocument = lazy(() => import("./modules/CreateDocument"));

const Search = lazy(() => import("./modules/Search"));

const EmailAction = lazy(() => import("./modules/EmailAction"));

const ManageUsers = lazy(() => import("./modules/ManageUsers"));

const MediaLibrary = lazy(() => import("./modules/MediaLibrary"));

const ForgotPassword = lazy(() => import("./modules/ForgotPassword"));

const ResetPassword = lazy(() => import("./modules/ResetPassword"));


const SecretRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Util.getAuthToken() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/signin",
            state: { from: props.location.pathname+props.location.hash+props.location.search }
          }}
        />
      )
    }
  />
);

const NoAuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !Util.getAuthToken() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/workspace",
            state: { from: props.location.pathname+props.location.hash+props.location.search }
          }}
        />
      )
    }
  />
);



const routes = (
  <Suspense fallback={<Filler />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/workspace"/>}/>
        <NoAuthRoute exact path="/signin" component={SignIn} />
        <NoAuthRoute exact path="/signup" component={SignUp} />
        <SecretRoute exact path="/workspace" component={Workspace} />
        <SecretRoute exact path="/documents" component={Documents} />
        <SecretRoute exact path="/create-document/:id" component={CreateDocument} />
        <Route exact path="/document/:id" component={CreateDocument} />
        <SecretRoute exact path="/search" component={Search} />
        <Route exact path="/emailAction" component={EmailAction} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <SecretRoute exact path="/manage-users" component={ManageUsers} />
        <SecretRoute exact path="/media-library" component={MediaLibrary} />
        <Route path="*" component={NotFound} />
      </Switch>
  </Suspense>
);

export default routes;
